import React from 'react';
import { Tag, Tooltip } from '@grafana/ui';
import { Vessel } from 'types';
import { DateTime } from 'luxon';

const ScuLastSeenTag = (props: { utcTime: DateTime; vessel: Vessel }) => {
  const { utcTime, vessel } = props;
  let lastSeen = '---';
  let colorIndex = 9;
  if (vessel.scu?.lastSeen) {
    const diff = utcTime
      .diff(DateTime.fromISO(vessel.scu?.lastSeen, { zone: 'utc' }))
      .shiftTo('hours', 'minutes', 'seconds');
    colorIndex = diff.hours > 0 ? 7 : 4;
    lastSeen = `${diff.hours}h:${diff.minutes}m`;
  }
  return (
    <Tooltip content="The time since last communication with the SCU">
      <Tag name={lastSeen} colorIndex={colorIndex} />
    </Tooltip>
  );
};

export default ScuLastSeenTag;
