import React, { useState, useEffect } from 'react';
import { useAppNotification } from 'notifications';
import { useMutation, useQuery } from '@apollo/client';
import { Alert, ConfirmModal, HorizontalGroup, Spinner, Tab, TabContent, TabsBar } from '@grafana/ui';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import {
  ConsumerConfig,
  DoubleRegisterOrder,
  EcomateConfig,
  FlowmeterConfig,
  NewOrExisting,
  QuadRegisterOrder,
  ScuConfig,
} from 'types';
import EditFlowmeters from 'components/EditFlowmeters';
import EditConsumers from 'components/EditConsumers';
import { GetVesselByIdInput, GetVesselByIdQuery, GetVesselsResult } from '../graphql/queries';
import {
  PublishConfigDraftMutation,
  PublishConfigDraftInput,
  PublishConfigDraftResult,
  SaveConfigDraftMutation,
  SaveConfigDraftInput,
  SaveConfigDraftResult,
  DeleteConfigDraftMutation,
  DeleteConfigDraftInput,
  DeleteConfigDraftResult,
} from '../graphql/mutations';
import ConfigSummary from 'components/ConfigSummary';
import GeneralSettings from 'components/GeneralSettings';

const getEmptyConfig = (): EcomateConfig => {
  return {
    consumers: [],
    flowmeters: [],
    modbusTcp: {
      doubleRegisterOrder: DoubleRegisterOrder.R1R2,
      quadRegisterOrder: QuadRegisterOrder.R1R2R3R4,
      enabled: false,
      port: 502,
    },
    nmea: {
      enabled: false,
    },
  };
};

type ConfigurationState = {
  activeTab: number;
  scuConfig?: ScuConfig;
  isDeleteDraftOpen: boolean;
};

const Configure = () => {
  const notifyApp = useAppNotification();
  const [activeTab, setActiveTab] = useState(0);
  const [state, setState] = useState<ConfigurationState>({
    activeTab: 1,
    isDeleteDraftOpen: false,
  });
  const { vesselid } = useParams<{ vesselid: string }>();
  const { loading, error, data } = useQuery<GetVesselsResult, GetVesselByIdInput>(GetVesselByIdQuery, {
    variables: {
      vesselid: vesselid,
    },
    fetchPolicy: 'no-cache',
  });
  const vessel = data?.vessels.length === 1 ? data?.vessels[0] : undefined;
  const [saveConfig] = useMutation<SaveConfigDraftResult, SaveConfigDraftInput>(SaveConfigDraftMutation, {
    onError: (err) => notifyApp.error('Error saving', 'An error occured, please try again'),
  });
  const [publishConfig] = useMutation<PublishConfigDraftResult, PublishConfigDraftInput>(PublishConfigDraftMutation, {
    onError: (err) => notifyApp.error('Error publishing', 'An error occured, please try again'),
  });
  const [deleteConfig] = useMutation<DeleteConfigDraftResult, DeleteConfigDraftInput>(DeleteConfigDraftMutation, {
    onError: (err) => notifyApp.error('Error deleting', 'Coult not delete draft config, please try again'),
  });

  /** Updates the state with the provided ecomateConfig and scuConfig.
   * The ecomateConfig is merged into the scuConfig
   */
  const updateConfig = (ecomateConfig: EcomateConfig, scuConfig: ScuConfig) => {
    setState((state) => ({
      ...state,
      scuConfig: {
        ...scuConfig,
        config: ecomateConfig,
      },
    }));
  };

  useEffect(() => {
    const cfg = vessel?.draftConfig ?? vessel?.activeConfig?.config;
    if (cfg) {
      updateConfig(_.cloneDeep(cfg.config), cfg);
    } else {
      setState((state) => ({
        ...state,
        scuConfig: undefined,
      }));
    }
  }, [data, vessel]);

  const newDraft = async () => {
    await saveDraft(getEmptyConfig());
  };

  const canPublish = () => {
    if (!state.scuConfig?.config) {
      return false;
    }
    const config = state.scuConfig.config;
    if (config.consumers.length === 0) {
      notifyApp.error('Consumers not added', 'Please add at least one consumer.');
      return false;
    } else if (config.flowmeters.length === 0) {
      notifyApp.error('Flowmeters not added', 'Please add at least one flowmeter.');
      return false;
    }
    return true;
  };

  const publishDraft = async (comment: string) => {
    const input: PublishConfigDraftInput = {
      input: {
        vesselId: vesselid,
        comment: comment,
      },
    };
    const result = await publishConfig({
      variables: input,
      refetchQueries: [GetVesselByIdQuery],
      awaitRefetchQueries: true,
    });
    if (result.data) {
      notifyApp.success('Configuration published', 'Configuration was successfully published');
    }
  };

  const removeNewItems = (config: EcomateConfig): EcomateConfig => {
    // When saving to the backend filter out any newly added items.
    // When the user clicks 'Save' on them the first time 'isNew' is set to false and it's saved.
    return {
      ...config,
      flowmeters: config.flowmeters.filter((f) => !(f as NewOrExisting<FlowmeterConfig>).isNew),
      consumers: config.consumers.filter((f) => !(f as NewOrExisting<ConsumerConfig>).isNew),
    };
  };

  const saveDraft = async (config: EcomateConfig) => {
    const input: SaveConfigDraftInput = {
      input: {
        config: JSON.stringify(removeNewItems(config)),
        vesselId: vesselid,
      },
    };
    const result = await saveConfig({
      variables: input,
    });
    const scuConfig = result.data?.saveConfigDraft?.scuConfig;
    if (scuConfig) {
      // After saving the draft we update the local state with the local config (the one sent to the API) and not with
      // the one we receive from the API. They are the same, but the received one would trigger re-rendering of child
      // components (due to new objects) which again can cause loss of data if multiple items are open for edit simultanously.
      updateConfig(config, scuConfig);
    }
  };
  const deleteDraft = async () => {
    const input: DeleteConfigDraftInput = {
      input: { vesselId: vesselid },
    };
    const result = await deleteConfig({
      variables: input,
    });
    if (result.data) {
      notifyApp.success('Deleted ', 'Configuration draft was deleted');
      setState((state) => ({
        ...state,
        scuConfig: vessel?.activeConfig ? _.cloneDeep(vessel.activeConfig.config) : undefined,
      }));
    }
  };

  if (loading) {
    return (
      <HorizontalGroup justify="center">
        <Spinner />
        Loading...
      </HorizontalGroup>
    );
  }
  if (error) {
    return (
      <Alert severity="error" title="Error loading">
        Could not load data, try refreshing page.
      </Alert>
    );
  }
  if (vessel === undefined) {
    return (
      <Alert severity="error" title="Not found">
        The vessel was not found
      </Alert>
    );
  }

  const config = state.scuConfig?.config;
  const activeConfig = vessel.activeConfig;
  const draftConfig = state.scuConfig?.draft ? state.scuConfig : undefined;
  return (
    <>
      <ConfirmModal
        isOpen={state.isDeleteDraftOpen}
        onConfirm={() => {
          setState((state) => ({ ...state, isDeleteDraftOpen: false }));
          deleteDraft();
        }}
        onDismiss={() => setState((state) => ({ ...state, isDeleteDraftOpen: false }))}
        title="Delete draft"
        body="This will delete the draft configuration"
        confirmText="Ok"
      />
      <ConfigSummary
        vessel={vessel.name ?? '(no name)'}
        activeConfig={activeConfig}
        draftConfig={draftConfig}
        onNew={newDraft}
        onPublish={publishDraft}
        canPublish={canPublish}
        onDelete={() => setState((state) => ({ ...state, isDeleteDraftOpen: true }))}
      />
      {config && (
        <>
          <TabsBar>
            <Tab key={0} active={activeTab === 0} label="General Settings" onChangeTab={() => setActiveTab(0)} />
            <Tab
              key={1}
              active={activeTab === 1}
              label="Flowmeters"
              onChangeTab={() => setActiveTab(1)}
              counter={config.flowmeters.length}
            />
            <Tab
              key={2}
              active={activeTab === 2}
              label="Consumers"
              onChangeTab={() => setActiveTab(2)}
              counter={config.consumers.length}
            />
          </TabsBar>
          <TabContent>
            {activeTab === 0 && <GeneralSettings config={config} onChanged={(c) => saveDraft(c)} />}
            {activeTab === 1 && (
              <EditFlowmeters
                flowmeters={config.flowmeters}
                onChanged={(flowmeters) => saveDraft({ ...config, flowmeters })}
              />
            )}
            {activeTab === 2 && (
              <EditConsumers
                consumers={config.consumers}
                flowmeters={config.flowmeters}
                onChanged={(consumers) => saveDraft({ ...config, consumers })}
              />
            )}
          </TabContent>
        </>
      )}
    </>
  );
};

export default Configure;
