import React, { useCallback } from 'react';
import { Field, Input, Select, VerticalGroup } from '@grafana/ui';
import _ from 'lodash';

import { ConsumerConfig, ConsumerMode, FlowmeterConfig, NewOrExisting, ConsumerType, NestedKeyOf } from 'types';
import Editor, { EditorValidator } from './Editor';
import { optionalNum, scale } from 'utils/misc';

const modeOptions = [
  {
    label: 'Inlet (Single flowmeter)',
    value: 'INLET',
  },
  {
    label: 'Loop (Inlet + outlet flowmeters)',
    value: 'LOOP',
  },
];

const ConsumerEditor = (props: {
  flowmeters: FlowmeterConfig[];
  consumer: ConsumerConfig;
  consumedEdited: (consumer: ConsumerConfig) => void;
  validator: EditorValidator<ConsumerConfig>;
}) => {
  const { consumer, consumedEdited: onEdited, flowmeters, validator } = props;
  const flowmeterOptions = flowmeters.map((f) => {
    return { label: f.name, value: f.id };
  });
  const consumerTypeOptions = Object.values(ConsumerType).map((t) => {
    return {
      label: _.startCase(_.toLower(_.replace(t, '_', ' '))),
      value: t,
    };
  });

  return (
    <div style={{ display: 'grid', gridGap: '8px', gridTemplateColumns: 'auto auto' }}>
      <Field label="Name" invalid={validator.isFieldInvalid('name')} error={validator.fieldError('name')}>
        <Input
          name="name"
          value={consumer.name}
          placeholder="Enter name"
          onBlur={(e) => validator.validate('name')}
          onChange={(e) =>
            onEdited({
              ...consumer,
              name: e.currentTarget.value,
            })
          }
        />
      </Field>
      <div style={{ alignSelf: 'center' }}>
        Examples: &apos;Main Engine&apos;, &apos;Main Engine 1&apos;, &apos;Aux Engine&apos;, &apos;Boiler&apos;, etc.
      </div>
      <Field label="Type" invalid={validator.isFieldInvalid('type')} error={validator.fieldError('type')}>
        <Select
          menuShouldPortal
          options={consumerTypeOptions}
          onBlur={() => validator.validate('type')}
          value={consumer.type}
          onChange={(e) => onEdited({ ...consumer, type: e.value })}
        />
      </Field>
      <div style={{ alignSelf: 'center' }}>Select the appropriate type.</div>
      <Field label="Measurement Mode" invalid={validator.isFieldInvalid('mode')} error={validator.fieldError('mode')}>
        <Select
          menuShouldPortal
          options={modeOptions}
          onBlur={() => validator.validate('mode')}
          value={consumer.mode}
          onChange={(e) => onEdited({ ...consumer, mode: e.value as ConsumerMode })}
        />
      </Field>
      <div style={{ alignSelf: 'center' }}>
        The measurement layout for this consumer.
        <br />
        Inlet: A single flowmeter measures the consumption.
        <br />
        Loop: An inlet and an outlet flowmeter measures the fuel circutation where the consumption is the difference
        betwen inlet and outlet.
      </div>
      <Field
        label="Inlet flowmeter"
        invalid={validator.isFieldInvalid('inletFlowmeterId')}
        error={validator.fieldError('inletFlowmeterId')}
      >
        <Select
          menuShouldPortal
          options={flowmeterOptions}
          value={consumer.inletFlowmeterId}
          onBlur={() => validator.validate('inletFlowmeterId')}
          onChange={(e) => onEdited({ ...consumer, inletFlowmeterId: e.value })}
        />
      </Field>
      <div style={{ alignSelf: 'center' }}>The flowmeter measuring the flow to the consumer.</div>
      {consumer.mode === 'LOOP' && (
        <>
          <Field
            label="Outlet flowmeter"
            invalid={validator.isFieldInvalid('outletFlowmeterId')}
            error={validator.fieldError('outletFlowmeterId')}
          >
            <Select
              menuShouldPortal
              options={flowmeterOptions}
              value={consumer.outletFlowmeterId}
              onBlur={() => validator.validate('outletFlowmeterId')}
              onChange={(e) => onEdited({ ...consumer, outletFlowmeterId: e.value })}
            />
          </Field>
          <div style={{ alignSelf: 'center' }}>The flowmeter measuring the return flow from the circulation loop.</div>
        </>
      )}
      <Field
        label="Minimum Consumption [kg/h]"
        invalid={validator.isFieldInvalid('minimumConsumption')}
        error={validator.fieldError('minimumConsumption')}
      >
        <Input
          name="minimumConsumption"
          type="number"
          value={scale(consumer.minimumConsumption, 3600)}
          onBlur={() => validator.validate('minimumConsumption')}
          onChange={(e) =>
            onEdited({ ...consumer, minimumConsumption: scale(optionalNum(e.currentTarget.value), 1 / 3600) })
          }
        />
      </Field>
      <div style={{ alignSelf: 'center' }}>
        The minimum/idle consumption for this consumer.
        <br />
        Used as a cutoff to detect if consumer is running or not. Measured flowrate must be more than half of this value
        to be counted as consumption.
      </div>
      <Field
        label="Maximum Consumption [kg/h]"
        invalid={validator.isFieldInvalid('maximumConsumption')}
        error={validator.fieldError('maximumConsumption')}
      >
        <Input
          name="maximumConsumption"
          type="number"
          value={scale(consumer.maximumConsumption, 3600)}
          onBlur={() => validator.validate('maximumConsumption')}
          onChange={(e) =>
            onEdited({ ...consumer, maximumConsumption: scale(optionalNum(e.currentTarget.value), 1 / 3600) })
          }
        />
      </Field>
      <div style={{ alignSelf: 'center' }}>
        The maximum expected consumption for this consumer. It is used on gauges, and should normally be rounded up to
        the nearest number.
        <br />
        F.ex. if expected maximum consumption is 355 kg/h it can be set to 400 kg/h.
      </div>
    </div>
  );
};

const ConsumerView = (props: { consumer: ConsumerConfig; flowmeters: FlowmeterConfig[] }) => {
  const { consumer, flowmeters } = props;
  const flowmeterOptions = flowmeters.map((f) => {
    return { label: f.name, value: f.id };
  });
  const modeText = modeOptions.find((option) => option.value === consumer.mode)?.label ?? consumer.mode;
  return (
    <VerticalGroup>
      <>Mode: {modeText}</>
      <>Type: {consumer.type ? _.startCase(_.toLower(_.replace(consumer.type, '_', ' '))) : '---'}</>
      <>
        Min / Max Consumption [kg/h]: {scale(consumer.minimumConsumption, 3600)} /{' '}
        {scale(consumer.maximumConsumption, 3600)}
      </>
      <>Inlet Flowmeter: {flowmeterOptions.find((f) => f.value === consumer.inletFlowmeterId)?.label ?? 'None'}</>
      {consumer.mode === 'LOOP' && (
        <>Outlet Flowmeter: {flowmeterOptions.find((f) => f.value === consumer.outletFlowmeterId)?.label ?? 'None'}</>
      )}
    </VerticalGroup>
  );
};

const EditConsumer = (props: {
  consumer: NewOrExisting<ConsumerConfig>;
  consumers: ConsumerConfig[];
  flowmeters: FlowmeterConfig[];
  onEdited: (flowmeter: NewOrExisting<ConsumerConfig>) => void;
  onDeleted: (consumer: NewOrExisting<ConsumerConfig>) => void;
  draggableId: string;
  index: number;
}) => {
  const { consumer, consumers, flowmeters, onEdited, onDeleted, draggableId, index } = props;

  const consumerValidator = useCallback(
    (consumer: ConsumerConfig, setInvalidField: (field: NestedKeyOf<ConsumerConfig>, message: string) => void) => {
      const otherConsumers = consumers.filter((c) => c.id !== consumer.id);
      if (!consumer.name || consumer.name === '') {
        setInvalidField('name', 'Name cannot be empty');
      } else if (otherConsumers.find((c) => c.name === consumer.name)) {
        setInvalidField('name', 'Name already used on another consumer');
      }

      if (consumer.minimumConsumption === undefined) {
        setInvalidField('minimumConsumption', 'Must be set');
      } else if (consumer.minimumConsumption < 0) {
        setInvalidField('minimumConsumption', 'Cannot be negative');
      }

      if (consumer.maximumConsumption === undefined) {
        setInvalidField('maximumConsumption', 'Must be set');
      } else if (consumer.maximumConsumption < 0) {
        setInvalidField('maximumConsumption', 'Cannot be negative');
      }

      if (
        consumer.maximumConsumption &&
        consumer.minimumConsumption &&
        consumer.maximumConsumption <= consumer.minimumConsumption
      ) {
        setInvalidField('minimumConsumption', 'Must be less than Maximum Consumption');
        setInvalidField('maximumConsumption', 'Must be greater than Minimum Consumption');
      }

      if (!consumer.inletFlowmeterId || consumer.inletFlowmeterId === '') {
        setInvalidField('inletFlowmeterId', 'Inlet flowmeter must be selected');
      }

      if (consumer.mode === ConsumerMode.LOOP && (!consumer.inletFlowmeterId || consumer.inletFlowmeterId === '')) {
        setInvalidField('outletFlowmeterId', 'Outlet flowmeter must be selected');
      }
    },
    [consumers]
  );

  return (
    <Editor<NewOrExisting<ConsumerConfig>>
      title={consumer.isNew ? 'New consumer' : consumer.name}
      item={consumer}
      onEdited={onEdited}
      initialEditMode={consumer.isNew}
      validator={consumerValidator}
      onDeleted={() => onDeleted(consumer)}
      onCancelled={() => {
        if (consumer.isNew) {
          // When clicking cancel on new items, just delete them
          onDeleted(consumer);
        }
      }}
      canDelete={!consumer.isNew}
      editor={(item, onEdited, validator) => (
        <ConsumerEditor consumer={item} flowmeters={flowmeters} consumedEdited={onEdited} validator={validator} />
      )}
      viewer={(item) => <ConsumerView consumer={item} flowmeters={flowmeters} />}
      draggableId={draggableId}
      index={index}
    />
  );
};

export default React.memo(EditConsumer);
