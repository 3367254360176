import { ScuAssignmentError } from 'graphql/mutations';
import { round } from 'lodash';

export const createGuid = (): string => {
  return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c: any) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
};

export const formatScuId = (scuId: string) => {
  // Prefix the string with leading zeroes to ensure it has the correct lenght
  const temp = ('000000000000' + scuId).slice(-12);
  return `${temp.substring(0, 2)}:${temp.substring(2, 4)}:${temp.substring(4, 6)}:${temp.substring(
    6,
    8
  )}:${temp.substring(8, 10)}:${temp.substring(10, 12)}`;
};

export const isScuValid = (scuId: string | undefined) => {
  const macRegex = new RegExp('^([0-9a-fA-F][0-9a-fA-F]:){5}([0-9a-fA-F][0-9a-fA-F])$');
  return !!scuId && macRegex.test(scuId);
};

export const optionalNum = (val: string): number | undefined => {
  if (!val || val === '') {
    return undefined;
  }
  return +val;
};

export const scale = (val: number | undefined, factor: number) => {
  return val === undefined || val === null ? undefined : val * factor;
};

export const optionalInt = (val: string): number | undefined => {
  if (!val || val === '') {
    return undefined;
  }
  return round(+val);
};

export const showScuAssignmentError = (
  notifyApp: {
    success: (title: string, text?: string | undefined) => any;
    warning: (title: string, text?: string | undefined) => any;
    error: (title: string, text?: string | undefined) => any;
  },
  scuAssignmentError?: ScuAssignmentError
) => {
  if (scuAssignmentError === 'ALREADY_ASSIGNED') {
    notifyApp.error('SCU is already in use', 'The entered SCU id is in use on another vessel');
  } else if (scuAssignmentError === 'OFFLINE') {
    notifyApp.error('SCU offline', 'Verify correct SCU id is entered and ensure SCU is online');
  } else if (scuAssignmentError === 'PENDING') {
    notifyApp.error(
      'SCU waiting for activation',
      'This SCU id has already been added and waiting for activation. Verify correct SCU id is entered and ensure SCU is online'
    );
  } else if (scuAssignmentError === 'RESERVED') {
    notifyApp.error(
      'SCU offline',
      'This SCU is reserved. Verify correct ID has been entered, if correct contact KROHNE Marine Support'
    );
  }
};
