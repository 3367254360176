import { ComponentClass } from 'react';
import { AppPlugin, AppRootProps } from '@grafana/data';
import App from './App';
import PluginConfig from './pages/PluginConfig';

export interface EcomateCompactAppSettings {
  datasource?: string;
}

const getPlugin = () => {
  let basePlugin = new AppPlugin<EcomateCompactAppSettings>()
    .setRootPage(App as unknown as ComponentClass<AppRootProps>)
    .addConfigPage({
      title: 'Settings',
      icon: 'cog',
      body: PluginConfig,
      id: 'settings',
    });
  return basePlugin;
};

export const plugin = getPlugin();
