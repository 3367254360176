import { gql } from '@apollo/client';
import { ScuConfig, Vessel, VesselMetadata } from 'types';
import { ScuConfigFields } from './fragments';

export const PublishConfigDraftMutation = gql`
  ${ScuConfigFields}
  mutation PublishConfigDraft($input: PublishConfigDraftInput!) {
    publishConfigDraft(input: $input) {
      scuConfig {
        ...ScuConfigFields
      }
      error
    }
  }
`;

export interface PublishConfigDraftInput {
  input: { vesselId: string; comment: string };
}

export interface PublishConfigDraftResult {
  publishConfigDraft: { scuConfig: ScuConfig; error?: DraftConfigError };
}

export const SaveConfigDraftMutation = gql`
  ${ScuConfigFields}
  mutation SaveConfigDraft($input: SaveConfigDraftInput!) {
    saveConfigDraft(input: $input) {
      scuConfig {
        ...ScuConfigFields
      }
    }
  }
`;

export interface SaveConfigDraftInput {
  input: { vesselId: string; config: string };
}

export interface SaveConfigDraftResult {
  saveConfigDraft: { scuConfig: ScuConfig };
}

export const DeleteConfigDraftMutation = gql`
  mutation DeleteConfigDraft($input: DeleteConfigDraftInput!) {
    deleteConfigDraft(input: $input) {
      error
    }
  }
`;

export interface DeleteConfigDraftInput {
  input: { vesselId: string };
}

export interface DeleteConfigDraftResult {
  deleteConfigDraft: { error?: DraftConfigError };
}

export type DraftConfigError = 'NO_DRAFT';

export const AddVesselMutation = gql`
  mutation AddVessel($input: AddVesselInput!) {
    addVessel(input: $input) {
      scuAssignmentError
      vessel {
        id
        name
      }
    }
  }
`;

export type ScuAssignmentError = 'ALREADY_ASSIGNED' | 'OFFLINE' | 'PENDING' | 'RESERVED';

export interface AddVesselInputPayload {
  scuId: string;
  vesselName: string;
  metadata?: VesselMetadata;
}

export interface AddVesselInput {
  input: AddVesselInputPayload;
}

export interface AddVesselResult {
  addVessel: {
    vessel?: Vessel;
    scuAssignmentError?: ScuAssignmentError;
  };
}

export const ReplaceVesselScuMutation = gql`
  mutation ReplaceVesselScu($input: ReplaceVesselScuInput!) {
    replaceVesselScu(input: $input) {
      scuAssignmentError
    }
  }
`;

export interface ReplaceVesselScuInputPayload {
  scuId: string;
  vesselId: string;
}

export interface ReplaceVesselScuInput {
  input: ReplaceVesselScuInputPayload;
}

export interface ReplaceVesselScuResult {
  replaceVesselScu: { scuAssignmentError?: ScuAssignmentError };
}

export const UpdateVesselMutation = gql`
  mutation UpdateVessel($input: UpdateVesselInput!) {
    updateVessel(input: $input) {
      vessel {
        id
        name
      }
    }
  }
`;

export interface UpdateVesselInputPayload {
  vesselId: string;
  vesselName: string;
  metadata?: VesselMetadata;
}

export interface UpdateVesselInput {
  input: UpdateVesselInputPayload;
}

export interface UpdateVesselResult {
  updateVessel: {
    vessel?: Vessel;
  };
}
