import React, { useMemo } from 'react';
import { ApolloClient, ApolloProvider, ApolloLink, HttpLink, InMemoryCache, concat } from '@apollo/client';
import { AppRootProps } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';
import Vessels from './pages/Vessels';
import Configure from './pages/Configure';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import NewVessel from 'pages/NewVessel';
import Vessel from 'pages/Vessel';
import useSettings from 'setting-hooks';
import { EcomateCompactAppSettings } from './module';

const App = (props: AppRootProps) => {
  const { path, url } = useRouteMatch();
  const { meta } = props;
  const [settings] = useSettings<EcomateCompactAppSettings>(meta.id);

  const backendSrv = getBackendSrv();

  const client = useMemo(() => {
    const httpLink = new HttpLink({
      uri: settings?.datasource,
      credentials: 'include',
      fetchOptions: { mode: 'cors' },
    });

    const orgLink = new ApolloLink((operation, forward) => {
      const orgId = (backendSrv as any)?.dependencies?.contextSrv?.user?.orgId;
      if (orgId) {
        operation.setContext(({ headers = {} }) => ({
          headers: {
            ...headers,
            'X-Grafana-Org-Id': orgId,
          },
        }));
      }
      return forward(operation);
    });

    return new ApolloClient<any>({
      cache: new InMemoryCache(),
      link: concat(orgLink, httpLink),
      defaultOptions: {
        query: {
          fetchPolicy: 'no-cache',
        },
      },
    });
  }, [settings?.datasource, backendSrv]);

  return (
    <ApolloProvider client={client}>
      <div>
        <div className="page-container page-header">
          <div className="page-header__inner">
            <span className="page-header__logo">
              <img width={64} height={64} src="public/plugins/krohnemarine-ecomatecompactadmin-app/img/logo.svg" />
            </span>
            <div className="page-header__info-block">
              <h1 className="page-header__title">EcoMATE Compact Administration</h1>
              <div className="page-header__sub-title">Add vessels and configure SCUs</div>
            </div>
          </div>
        </div>
        <div className="page-container page-body">
          <Switch>
            {/* In case URL ends with / trim it off by redirecting, otherwise links above will have double // */}
            <Redirect from="/:url*(/+)" to={window.location.pathname.slice(0, -1)} />
            <Route exact path={path}>
              <Vessels baseUrl={url} />
            </Route>
            <Route path={`${path}/vessel/:vesselid`}>
              <Vessel baseUrl={url} />
            </Route>
            <Route path={`${path}/config/:vesselid`}>
              <Configure />
            </Route>
            <Route path={`${path}/new-vessel`}>
              <NewVessel baseUrl={url} />
            </Route>
            <Route path="*">Not found</Route>
          </Switch>
        </div>
      </div>
    </ApolloProvider>
  );
};

export default App;
