import React, { useState } from 'react';
import { ConfirmModal, VerticalGroup, Field, Input, Tooltip, Button } from '@grafana/ui';
import { Scu } from 'types';
import { isScuValid } from 'utils/misc';

const ReplaceScu = (props: { scu?: Scu; onReplace: (scuId: string) => Promise<boolean> }) => {
  const { scu, onReplace } = props;
  const [state, setState] = useState({
    isOpen: false,
    scuId: '' as string | undefined,
    scuValid: true,
  });

  const validate = () => {
    const scuValid = isScuValid(state.scuId);
    if (scuValid !== state.scuValid) {
      setState({ ...state, scuValid: scuValid });
    }
  };
  const updateScuId = (value: string | undefined) => {
    const scuValid = isScuValid(value);
    setState({ ...state, scuId: value, scuValid: scuValid });
  };
  const confirm = async () => {
    if (state.scuId) {
      const result = await onReplace(state.scuId);
      if (result) {
        setState({ ...state, isOpen: false });
      }
    }
  };
  return (
    <>
      <ConfirmModal
        isOpen={state.isOpen}
        onConfirm={confirm}
        onDismiss={() => setState({ ...state, isOpen: false })}
        title={scu ? 'Replace SCU' : 'Add SCU'}
        body={
          <VerticalGroup>
            Enter the id of the new SCU
            <Field
              label="SCU Id"
              invalid={!state.scuValid}
              error="Invalid format (Valid format is xx:xx:xx:xx:xx:xx, where x is 0-9/a-f)"
            >
              <Input
                name="scuid"
                onBlur={(e) => validate()}
                placeholder="xx:xx:xx:xx:xx:xx"
                value={state.scuId}
                onChange={(e) => updateScuId(e.currentTarget.value)}
              />
            </Field>
          </VerticalGroup>
        }
        confirmText="Ok"
      />
      <Tooltip
        content={
          scu
            ? 'Replace the SCU used on this vessel with another (f.ex. if the SCU has failed and is replaced)'
            : 'Add a SCU to this vessel'
        }
      >
        <Button key="settings" variant="primary" onClick={() => setState({ isOpen: true, scuId: '', scuValid: true })}>
          {scu ? 'Replace SCU' : 'Add SCU'}
        </Button>
      </Tooltip>
    </>
  );
};

export default ReplaceScu;
