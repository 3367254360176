import React from 'react';
import { Field, FormAPI, Input, InputControl, Select, VerticalGroup } from '@grafana/ui';
import _ from 'lodash';

import {
  FlowmeterConfig,
  QuadRegisterOrder,
  DoubleRegisterOrder,
  NewOrExisting,
  OptimassConverterType,
  OptimassType,
  FlowmeterFamily,
  FlowsonicModel,
} from 'types';
import FormEditor from './FormEditor';

const flowmeterFamilies = [
  {
    label: 'Optimass',
    value: FlowmeterFamily.Optimass,
  },
  {
    label: 'Flowsonic',
    value: FlowmeterFamily.Flowsonic,
  },
];

const flowsonicOptions = [
  {
    label: 'i450',
    value: FlowsonicModel.I450,
  },
  {
    label: 'i400',
    value: FlowsonicModel.I400,
  },
];

const converterOptions = [
  {
    label: 'MFC010',
    value: OptimassConverterType.MFC010,
  },
  {
    label: 'MFC300',
    value: OptimassConverterType.MFC300,
  },
  {
    label: 'MFC400 ER1',
    value: OptimassConverterType.MFC400ER1,
  },
  {
    label: 'MFC400 ER2',
    value: OptimassConverterType.MFC400ER2,
  },
  {
    label: 'MFC400 ER2.1.4',
    value: OptimassConverterType.MFC400ER2_1_4,
  },
];

const showRegisterOrder = (converter: OptimassConverterType | undefined) =>
  converter && converter !== 'MFC400ER2' && converter !== 'MFC400ER2_1_4';

const FlowmeterView = (props: { flowmeter: FlowmeterConfig }) => {
  const { flowmeter: editedFlowmeter } = props;
  if (editedFlowmeter.family === FlowmeterFamily.Optimass && editedFlowmeter.optimassSettings) {
    return (
      <VerticalGroup>
        <>Type: Optimass {editedFlowmeter.optimassSettings.model}</>
        <>
          Converter:{' '}
          {converterOptions.find((option) => option.value === editedFlowmeter.optimassSettings?.converter)?.label ??
            editedFlowmeter.optimassSettings.converter}
        </>
        <>Modbus slave: {editedFlowmeter.modbusSettings.slaveAddress}</>
      </VerticalGroup>
    );
  }
  if (
    editedFlowmeter.family === FlowmeterFamily.Flowsonic &&
    editedFlowmeter.flowsonicSettings?.model === FlowsonicModel.I450
  ) {
    return (
      <VerticalGroup>
        <>Type: Flowsonic {editedFlowmeter.flowsonicSettings.model}</>
        <>Serial number: {editedFlowmeter.flowsonicSettings.serialNumber}</>
      </VerticalGroup>
    );
  }
  if (editedFlowmeter.family === FlowmeterFamily.Flowsonic && editedFlowmeter.flowsonicSettings) {
    return (
      <VerticalGroup>
        <>Type: Flowsonic {editedFlowmeter.flowsonicSettings.model}</>
        <>Modbus slave: {editedFlowmeter.modbusSettings.slaveAddress}</>
      </VerticalGroup>
    );
  }
  return (
    <VerticalGroup>
      <>Unknown type</>
    </VerticalGroup>
  );
};

const FlowmeterEditorForm = (props: { formApi: FormAPI<NewOrExisting<FlowmeterConfig>> }) => {
  const optimassOptions = Object.values(OptimassType).map((v) => ({ label: v, value: v }));
  const { register, control, errors, watch } = props.formApi;
  const family = watch('family');
  const flowsonicModel = watch('flowsonicSettings.model');
  const converter = watch('optimassSettings.converter');
  return (
    <div style={{ display: 'grid', gridGap: '8px', gridTemplateColumns: 'auto auto' }}>
      <Field label="Name" invalid={!!errors.name} error={errors.name?.message}>
        <Input {...register('name', { required: 'Please enter a valid name' })} name="name" placeholder="Enter name" />
      </Field>
      <div style={{ alignSelf: 'center' }}>
        It is recommended to use the name of the consumer and Inlet/Outlet suffix.
        <br />
        Examples: &apos;Main Engine Inlet&apos;, &apos;Main Engine Outlet&apos;, &apos;Aux Engine Inlet&apos;.
      </div>
      <Field label="Flowmeter Family" invalid={!!errors.family} error={errors.family?.message}>
        <InputControl
          {...register('family', { required: 'Please select a flowmeter family' })}
          render={({ field }) => (
            <Select {...field} options={flowmeterFamilies} onChange={(e) => field.onChange(e.value)} />
          )}
          name="family"
          control={control}
        />
      </Field>
      <div style={{ alignSelf: 'center' }}>
        The flowmeter family.
        <br />
        Warning: If it does not match the connected flowmeter, the communcation will fail.
      </div>
      {family === FlowmeterFamily.Flowsonic && (
        <>
          <Field
            label="Flowsonic type"
            invalid={!!errors.flowsonicSettings?.model}
            error={errors.flowsonicSettings?.model?.message}
          >
            <InputControl
              {...register('flowsonicSettings.model', { required: 'Please select the model' })}
              render={({ field }) => (
                <Select
                  menuShouldPortal
                  {...field}
                  options={flowsonicOptions}
                  onChange={(e) => field.onChange(e.value)}
                />
              )}
              name="flowsonicSettings.model"
              control={control}
            />
          </Field>
          <div style={{ alignSelf: 'center' }}>
            The Flowsonic model. This can be found by looking at label on the flowmeter.
            <br />
            Warning: If the type does not match the connected flowmeter, the communcation will fail.
          </div>
          <Field
            label="Serial number"
            invalid={!!errors.flowsonicSettings?.serialNumber}
            error={errors.flowsonicSettings?.serialNumber?.message}
          >
            <Input
              {...register('flowsonicSettings.serialNumber', {
                required: 'Please provide a valid serial number',
                valueAsNumber: true,
              })}
              type="number"
              name="flowsonicSettings.serialNumber"
              placeholder="Enter serial number"
            />
          </Field>
          <div style={{ alignSelf: 'center' }}>
            The serial number of the flowmeter. This can be found by looking at label on the flowmeter.
            <br />
            Warning: The serial number is used to idenfity the flowmeter so it must match the mounted meter.
          </div>
        </>
      )}
      {family === FlowmeterFamily.Optimass && (
        <>
          <Field
            label="Optimass Converter"
            invalid={!!errors.optimassSettings?.converter}
            error={errors.optimassSettings?.converter?.message}
          >
            <InputControl
              {...register('optimassSettings.converter', { required: 'Please select a converter' })}
              render={({ field }) => (
                <Select {...field} options={converterOptions} onChange={(e) => field.onChange(e.value)} />
              )}
              name="optimassSettings.converter"
              control={control}
            />
          </Field>
          <div style={{ alignSelf: 'center' }}>
            The Optimass Converter type. This can be found by looking at label on the flowmeter converter.
            <br />
            Warning: If the type does not match the connected flowmeter, the communcation will fail.
          </div>
          <Field
            label="Optimass Type"
            invalid={!!errors.optimassSettings?.model}
            error={errors.optimassSettings?.model?.message}
          >
            <InputControl
              {...register('optimassSettings.model', { required: 'Please select the model' })}
              render={({ field }) => (
                <Select {...field} options={optimassOptions} onChange={(e) => field.onChange(e.value)} />
              )}
              name="optimassSettings.model"
              control={control}
            />
          </Field>
          <div style={{ alignSelf: 'center' }}>
            The Optimass flowmeter type. This can be found by looking at the label of the flowmeter.
            <br />
          </div>
        </>
      )}
      {(family !== FlowmeterFamily.Flowsonic || flowsonicModel === FlowsonicModel.I400) && (
        <>
          <Field
            label="Modbus Slave"
            invalid={!!errors.modbusSettings?.slaveAddress}
            error={errors.modbusSettings?.slaveAddress?.message}
          >
            <Input
              {...register('modbusSettings.slaveAddress', {
                valueAsNumber: true,
                validate: {
                  valid: (value: number) => (!(value >= 1 && value <= 247) ? 'Enter a value from 1 to 247' : undefined),
                },
              })}
              type="number"
              placeholder="Enter slave ID"
            />
          </Field>
          <div style={{ alignSelf: 'center' }}>
            The Modbus Slave adress of this flowmeter. Valid range are 1 to 247.
            <br />
            Must be unique pr. flowmeter.
            <br />
            Consult the flowmeter user manual on how to read/set on the flowmeter.
          </div>
        </>
      )}
      {showRegisterOrder(converter) && (
        <>
          <Field
            label="Modbus QuadRegister order"
            invalid={!!errors.modbusSettings?.quadRegisterOrder}
            error={errors.modbusSettings?.quadRegisterOrder?.message}
          >
            <InputControl
              {...register('modbusSettings.quadRegisterOrder', { required: 'Please select the register order' })}
              render={({ field }) => (
                <Select
                  {...field}
                  options={Object.keys(QuadRegisterOrder).map((key) => {
                    return { value: key, label: key };
                  })}
                  onChange={(e) => field.onChange(e.value)}
                />
              )}
              name="modbusSettings.quadRegisterOrder"
              control={control}
            />
          </Field>
          <div style={{ alignSelf: 'center' }}>
            The register order for values containing 4 registers, default is R4R3R2R1.
            <br />
            If incorrect, will cause incorrect readings. Check flowmeter settings if unsure.
          </div>
          <Field
            label="Modbus DoubleRegister order"
            invalid={!!errors.modbusSettings?.doubleRegisterOrder}
            error={errors.modbusSettings?.doubleRegisterOrder?.message}
          >
            <InputControl
              {...register('modbusSettings.doubleRegisterOrder', { required: 'Please select the register order' })}
              render={({ field }) => (
                <Select
                  {...field}
                  options={Object.keys(DoubleRegisterOrder).map((key) => {
                    return { value: key, label: key };
                  })}
                  onChange={(e) => field.onChange(e.value)}
                />
              )}
              name="modbusSettings.doubleRegisterOrder"
              control={control}
            />
          </Field>
          <div style={{ alignSelf: 'center' }}>
            The register order for values containing 2 registers, default is R2R1.
            <br />
            If incorrect, will cause incorrect readings. Check flowmeter settings if unsure.
          </div>
        </>
      )}
    </div>
  );
};

const EditFlowmeter = (props: {
  flowmeter: NewOrExisting<FlowmeterConfig>;
  onEdited: (flowmeter: NewOrExisting<FlowmeterConfig>) => void;
  onDeleted: (flowmeter: NewOrExisting<FlowmeterConfig>) => void;
  draggableId: string;
  index: number;
}) => {
  const { flowmeter, onEdited, onDeleted, draggableId, index } = props;
  const title = flowmeter.isNew ? 'New flowmeter' : flowmeter.name;
  const edited = (item: NewOrExisting<FlowmeterConfig>) => {
    // Depending on the configured flowmeter family, clear unused settings to avoid storing redundant data.
    if (item.family === FlowmeterFamily.Optimass) {
      item = {
        ...item,
        flowsonicSettings: undefined,
      };
    } else if (item.family === FlowmeterFamily.Flowsonic) {
      item = {
        ...item,
        optimassSettings: undefined,
      };
    }
    onEdited(item);
  };
  return (
    <FormEditor
      item={flowmeter}
      canDelete={!flowmeter.isNew}
      onEdited={edited}
      onDeleted={() => onDeleted(flowmeter)}
      onCancelled={() => {
        if (flowmeter.isNew) {
          // When clicking cancel on new items, just delete them
          onDeleted(flowmeter);
        }
      }}
      draggableId={draggableId}
      title={title}
      index={index}
      editor={(fomrApi) => <FlowmeterEditorForm formApi={fomrApi} />}
      viewer={(item) => <FlowmeterView flowmeter={item} />}
    />
  );
};

export default React.memo(EditFlowmeter);
