import { Field, Input } from '@grafana/ui';
import React from 'react';
import { Vessel } from 'types';
import { EditorValidator } from './Editor';
import VesselMetadataEditor from './VesselMetadataEditor';

const VesselFieldEditor = (props: {
  vessel: Vessel;
  vesselChanged?: (vessel: Vessel) => void;
  validator: EditorValidator<Vessel>;
  showScuId?: boolean;
}) => {
  const { vessel, vesselChanged, validator, showScuId } = props;
  return (
    <>
      <div style={{ display: 'grid', gridGap: '8px', gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))' }}>
        {showScuId && (
          <Field label="SCU Id" invalid={validator.isFieldInvalid('scuId')} error={validator.fieldError('scuId')}>
            <Input
              name="scuId"
              onBlur={() => validator.validate('scuId')}
              placeholder="xx:xx:xx:xx:xx:xx"
              value={vessel.scuId}
              onChange={(e) => vesselChanged && vesselChanged({ ...vessel, scuId: e.currentTarget.value })}
            />
          </Field>
        )}
        <Field label="Name" invalid={validator.isFieldInvalid('name')} error={validator.fieldError('name')}>
          <Input
            name="name"
            onBlur={() => validator.validate('name')}
            value={vessel.name}
            onChange={(e) => vesselChanged && vesselChanged({ ...vessel, name: e.currentTarget.value })}
          />
        </Field>
      </div>
      <p>Optional fields:</p>
      <div style={{ display: 'grid', gridGap: '8px', gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))' }}>
        <VesselMetadataEditor
          data={vessel ?? {}}
          dataChanged={(data) => vesselChanged && vesselChanged({ ...vessel, ...data })}
          validator={validator}
        />
      </div>
    </>
  );
};

export default VesselFieldEditor;
