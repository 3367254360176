import React, { useState, CSSProperties } from 'react';
import { Button, Card, Form, FormAPI, HorizontalGroup, Icon, IconButton, Tooltip } from '@grafana/ui';
import _ from 'lodash';

import { NewOrExisting } from 'types';
import { Draggable, DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

export type FormEditorProps<T extends {}> = {
  title?: string;
  canDelete: boolean;
  canCancel?: boolean;
  item: NewOrExisting<T>;
  onEdited: (item: T) => Promise<boolean> | boolean | void;
  onDeleted?: () => void;
  onCancelled?: () => void;
  editor: (formApi: FormAPI<NewOrExisting<T>>) => JSX.Element;
  viewer: (item: T) => JSX.Element;
  index?: number;
  draggableId?: string;
};

const getItemStyle = (isDragging: boolean, draggableStyle: any): CSSProperties => ({
  ...draggableStyle,
  opacity: isDragging ? 0.7 : 1,
});

const FormEditor = <T extends {}>(props: FormEditorProps<T>) => {
  const { 
    title,
    draggableId,
    editor,
    viewer,
    item,
    index,
    onEdited,
    onDeleted,
    onCancelled, 
    canCancel = true, 
    canDelete 
  } = props;
  const initialEditMode = item.isNew;
  const [isBusy, setIsBusy] = useState(false);
  const [editMode, setEditMode] = useState(initialEditMode ?? false);

  const apply = async (editedItem: T) => {
    let res = onEdited(_.cloneDeep(editedItem));
    if (typeof res === 'object') {
      setIsBusy(true);
      res = await res;
      setIsBusy(false);
    }
    if (res !== false) {
      setEditMode(false);
    }
  };
  const cancel = () => {
    setEditMode(false);
    if (item && onCancelled) {
      onCancelled();
    }
  };

  const renderEditor = (dragHandleProps?: DraggableProvidedDragHandleProps | undefined) => {
    return (
      <Card>
        <Card.Heading>
          {title}
          {!editMode && (
            <HorizontalGroup justify="flex-end">
              {dragHandleProps && (
                <div {...dragHandleProps} style={{ display: 'inline-block' }}>
                  <Tooltip content="Drag to change order">
                    <Icon name="draggabledots" />
                  </Tooltip>
                </div>
              )}
              <IconButton name="cog" tooltip={'Edit'} onClick={() => setEditMode(!editMode)} />
            </HorizontalGroup>
          )}
        </Card.Heading>
        <Card.Description>
          {editMode ? (
            <Form defaultValues={item as any} onSubmit={apply}>
              {(formApi) => (
                <>
                  {editor(formApi)}
                  <HorizontalGroup>
                  <Button key="settings" variant="primary" type="submit" disabled={isBusy || !formApi.formState.isDirty}>
                    Save
                  </Button>
                  {canCancel && (
                    <Button key="cancel" variant="secondary" onClick={cancel} disabled={isBusy}>
                      Cancel
                    </Button>
                  )}
                  {canDelete && (
                    <Button key="delete" variant="destructive" onClick={() => onDeleted?.()} disabled={isBusy}>
                      Delete
                    </Button>
                  )}
                  </HorizontalGroup>
                </>
              )}
            </Form>
          ) : (
            viewer(item)
          )}
        </Card.Description>
      </Card>
    );
  };

  return draggableId !== undefined && index !== undefined ? (
    <Draggable draggableId={draggableId} index={index} isDragDisabled={editMode}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          {renderEditor(provided.dragHandleProps ?? undefined)}
        </div>
      )}
    </Draggable>
  ) : (
    renderEditor()
  );
};

export default FormEditor;
