import React, { useState, useEffect, CSSProperties } from 'react';
import { Button, Card, Icon, IconButton, Tooltip } from '@grafana/ui';
import { Draggable, DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import _ from 'lodash';
import { NestedKeyOf } from 'types';

export type ValidationError<T> = {
  field: NestedKeyOf<T>;
  message: string;
};

export type EditorProps<T extends {}> = {
  title?: string;
  canDelete: boolean;
  canCancel?: boolean;
  item: T;
  initialEditMode?: boolean;
  onEdited: (item: T) => Promise<boolean> | boolean | void;
  onDeleted?: () => void;
  onCancelled?: () => void;
  validator?: (item: T, setInvalidField: (field: NestedKeyOf<T>, message: string) => void) => void;
  editor: (item: T, onEdited: (item: T) => void, validator: EditorValidator<T>) => JSX.Element;
  viewer: (item: T) => JSX.Element;
  index?: number;
  draggableId?: string;
};

export interface EditorValidator<T extends {}> {
  validate: (field?: NestedKeyOf<T>) => Array<ValidationError<T>>;
  isFieldInvalid: (field: NestedKeyOf<T>) => boolean;
  fieldError: (field: NestedKeyOf<T>) => string | undefined;
}

const getItemStyle = (isDragging: boolean, draggableStyle: any): CSSProperties => ({
  ...draggableStyle,
  opacity: isDragging ? 0.7 : 1,
});

const Editor = <T extends {}>(props: EditorProps<T>) => {
  const {
    title,
    canDelete,
    canCancel = true,
    item,
    initialEditMode,
    onEdited,
    onDeleted,
    onCancelled,
    validator,
    editor,
    viewer,
    index,
    draggableId,
  } = props;
  const [isBusy, setIsBusy] = useState(false);
  const [editMode, setEditMode] = useState(initialEditMode ?? false);
  const [fieldsToValidate, setFieldsToValidate] = useState<Array<NestedKeyOf<T>> | undefined>([]);
  const [invalidFields, setInvalidFields] = useState<Array<ValidationError<T>>>([]);
  const [editedItem, setEditedItem] = useState<T>(_.cloneDeep(item));
  const [isDirty, setIsDirty] = useState(false);

  const isFieldInvalid = (field: NestedKeyOf<T>) => {
    return invalidFields.findIndex((f) => f.field === field) !== -1;
  };
  const fieldError = (field: NestedKeyOf<T>) => {
    return invalidFields.find((f) => f.field === field)?.message;
  };

  const validate = (field?: NestedKeyOf<T>) => {
    const invalidFields = [] as Array<ValidationError<T>>;
    if (validator) {
      validator(editedItem, (f: NestedKeyOf<T>, m: string) => {
        if (
          field === undefined ||
          field === f ||
          fieldsToValidate === undefined ||
          fieldsToValidate.indexOf(f) !== -1
        ) {
          invalidFields.push({ field: f, message: m });
        }
      });
    }
    if (field && fieldsToValidate && fieldsToValidate.indexOf(field) === -1) {
      setFieldsToValidate([...fieldsToValidate, field]);
    } else if (!field) {
      setFieldsToValidate(undefined);
    }
    setInvalidFields(invalidFields);
    return invalidFields;
  };

  useEffect(() => {
    setEditedItem(item);
  }, [item]);

  const wrappedValidator = {
    validate: validate,
    isFieldInvalid: isFieldInvalid,
    fieldError: fieldError,
  } as EditorValidator<T>;

  const edited = (item: T) => {
    setIsDirty(true);
    setEditedItem(item);
  };

  const apply = async () => {
    const result = validate();
    if (result.length === 0) {
      let res = onEdited(editedItem);
      if (typeof res === 'object') {
        setIsBusy(true);
        res = await res;
        setIsBusy(false);
      }
      if (res !== false) {
        setIsDirty(false);
        setEditMode(false);
        setInvalidFields([]);
        setFieldsToValidate([]);
      }
    }
  };
  const cancel = () => {
    setEditedItem(item);
    setEditMode(false);
    setInvalidFields([]);
    setFieldsToValidate([]);
    if (editedItem && onCancelled) {
      onCancelled();
    }
  };
  const deleteItem = () => {
    if (onDeleted) {
      onDeleted();
    }
  };

  const renderEditor = (dragHandleProps?: DraggableProvidedDragHandleProps | undefined) => {
    return (
      <Card>
        <Card.Heading>
          {title}
          {!editMode && (
            <div>
              {dragHandleProps && (
                <div {...dragHandleProps} style={{ display: 'inline-block' }}>
                  <Tooltip content="Drag to change order">
                    <Icon name="draggabledots" style={{ height: 16, verticalAlign: 'top', marginRight: 10 }} />
                  </Tooltip>
                </div>
              )}
              {!editMode && <IconButton name="cog" tooltip={'Edit'} onClick={() => setEditMode(!editMode)} />}
            </div>
          )}
        </Card.Heading>
        <Card.Description>
          {editMode ? editor(editedItem, edited, wrappedValidator) : viewer(editedItem)}
        </Card.Description>
        {editMode && (
          <Card.Actions>
            <Button key="settings" variant="primary" onClick={apply} disabled={!isDirty || isBusy}>
              Save
            </Button>
            {canCancel && (
              <Button key="cancel" variant="secondary" onClick={cancel} disabled={isBusy}>
                Cancel
              </Button>
            )}
            {canDelete && (
              <Button key="delete" variant="destructive" onClick={deleteItem} disabled={isBusy}>
                Delete
              </Button>
            )}
          </Card.Actions>
        )}
      </Card>
    );
  };

  return draggableId !== undefined && index !== undefined ? (
    <Draggable draggableId={draggableId} index={index} isDragDisabled={editMode}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          {renderEditor(provided.dragHandleProps ?? undefined)}
        </div>
      )}
    </Draggable>
  ) : (
    renderEditor()
  );
};

export default Editor;
