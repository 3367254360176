import { useCallback, useEffect, useState } from 'react';
import { fetchSettings } from './fetchSettings';
import { setSettings as _setSettings } from './setSettings';
import { enablePlugin as _enablePlugin, disablePlugin as _disablePlugin } from './enablePlugin';

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default function useSettings<T>(pluginId: string): [T | undefined, (e: T) => void, (e: T) => void, () => void] {
  const [settings, setSettings] = useState<T>();

  useEffect(() => {
    fetchSettings<T>(pluginId).then((e) => setSettings(e));
  }, [pluginId]);

  const updateSettings = useCallback(
    (settings: T) => {
      const _ = async (e: T) => {
        await _setSettings(pluginId, e);
        const res = await fetchSettings<T>(pluginId);
        setSettings(res);
      };
      _(settings);
    },
    [pluginId]
  );

  const enablePlugin = useCallback((settings: T) => {
    const _ = async (e: T) => {
      await _enablePlugin(pluginId, e);
      await sleep(500);
      window.location.reload();
    };
    _(settings);
  }, []);

  // TODO: Should the settings be wiped on disable?
  const disablePlugin = useCallback(() => {
    const _ = async () => {
      await _disablePlugin(pluginId);
      await sleep(500);
      window.location.reload();
    };
    _();
  }, []);

  return [settings, updateSettings, enablePlugin, disablePlugin];
}
