import React from 'react';
import { Field, Input } from '@grafana/ui';
import { Vessel, VesselMetadata } from 'types';
import { optionalNum, optionalInt } from 'utils/misc';
import { EditorValidator } from './Editor';

const VesselMetadataEditor = (props: {
  loading?: boolean;
  readonly?: boolean;
  data: VesselMetadata;
  dataChanged: (data: VesselMetadata) => void;
  validator: EditorValidator<Vessel>;
}) => {
  const { loading, readonly, data, dataChanged, validator } = props;
  return (
    <>
      <Field
        label="IMO #"
        disabled={loading}
        invalid={validator.isFieldInvalid('imo')}
        error={validator.fieldError('imo')}
      >
        <Input
          name="imo"
          readOnly={readonly}
          onBlur={() => validator.validate('imo')}
          value={data.imo}
          onChange={(e) => dataChanged({ ...data, imo: e.currentTarget.value })}
        />
      </Field>
      <Field
        label="MMSI"
        disabled={loading}
        invalid={validator.isFieldInvalid('mMSI')}
        error={validator.fieldError('mMSI')}
      >
        <Input
          name="mmsi"
          readOnly={readonly}
          onBlur={() => validator.validate('mMSI')}
          value={data.mMSI}
          onChange={(e) => dataChanged({ ...data, mMSI: e.currentTarget.value })}
        />
      </Field>
      <Field
        label="Vessel Type"
        disabled={loading}
        invalid={validator.isFieldInvalid('vesselType')}
        error={validator.fieldError('vesselType')}
      >
        <Input
          name="vesseltype"
          readOnly={readonly}
          onBlur={() => validator.validate('vesselType')}
          value={data.vesselType}
          onChange={(e) => dataChanged({ ...data, vesselType: e.currentTarget.value })}
        />
      </Field>
      <Field
        label="Operating Status"
        disabled={loading}
        invalid={validator.isFieldInvalid('operatingStatus')}
        error={validator.fieldError('operatingStatus')}
      >
        <Input
          name="operatingstatus"
          readOnly={readonly}
          onBlur={() => validator.validate('operatingStatus')}
          value={data.operatingStatus}
          onChange={(e) => dataChanged({ ...data, operatingStatus: e.currentTarget.value })}
        />
      </Field>
      <Field
        label="Flag"
        disabled={loading}
        invalid={validator.isFieldInvalid('flag')}
        error={validator.fieldError('flag')}
      >
        <Input
          name="flag"
          readOnly={readonly}
          onBlur={() => validator.validate('flag')}
          value={data.flag}
          onChange={(e) => dataChanged({ ...data, flag: e.currentTarget.value })}
        />
      </Field>
      <Field
        label="Gross Tonnage"
        disabled={loading}
        invalid={validator.isFieldInvalid('grossTonnage')}
        error={validator.fieldError('grossTonnage')}
      >
        <Input
          name="grosstonnage"
          readOnly={readonly}
          type="number"
          onBlur={() => validator.validate('grossTonnage')}
          value={data.grossTonnage}
          onChange={(e) => dataChanged({ ...data, grossTonnage: optionalNum(e.currentTarget.value) })}
        />
      </Field>
      <Field
        label="Dead Weight"
        disabled={loading}
        invalid={validator.isFieldInvalid('deadweight')}
        error={validator.fieldError('deadweight')}
      >
        <Input
          name="deadweight"
          readOnly={readonly}
          type="number"
          onBlur={() => validator.validate('deadweight')}
          value={data.deadweight}
          onChange={(e) => dataChanged({ ...data, deadweight: optionalNum(e.currentTarget.value) })}
        />
      </Field>
      <Field
        label="Length [m]"
        disabled={loading}
        invalid={validator.isFieldInvalid('length')}
        error={validator.fieldError('length')}
      >
        <Input
          name="length"
          readOnly={readonly}
          type="number"
          onBlur={() => validator.validate('length')}
          value={data.length}
          onChange={(e) => dataChanged({ ...data, length: optionalNum(e.currentTarget.value) })}
        />
      </Field>
      <Field
        label="Breadth [m]"
        disabled={loading}
        invalid={validator.isFieldInvalid('breadth')}
        error={validator.fieldError('breadth')}
      >
        <Input
          name="breadth"
          readOnly={readonly}
          type="number"
          onBlur={() => validator.validate('breadth')}
          value={data.breadth}
          onChange={(e) => dataChanged({ ...data, breadth: optionalNum(e.currentTarget.value) })}
        />
      </Field>
      <Field
        label="Year built"
        disabled={loading}
        invalid={validator.isFieldInvalid('yearBuilt')}
        error={validator.fieldError('yearBuilt')}
      >
        <Input
          name="yearbuilt"
          readOnly={readonly}
          type="number"
          onBlur={() => validator.validate('yearBuilt')}
          value={data.yearBuilt}
          onChange={(e) => dataChanged({ ...data, yearBuilt: optionalInt(e.currentTarget.value) })}
        />
      </Field>
    </>
  );
};

export default VesselMetadataEditor;
