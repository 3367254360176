import React, { useCallback } from 'react';
import { NestedKeyOf, Vessel } from 'types';
import Editor from './Editor';
import VesselFieldEditor from './VesselFieldEditor';
import VesselView from './VesselView';

const VesselEditor = (props: {
  vessel: Vessel;
  vesselEdited: (vessel: Vessel) => Promise<boolean> | boolean | void;
  newMode?: boolean;
}) => {
  const { vessel, vesselEdited, newMode } = props;

  const vesselValidator = useCallback(
    (settings: Vessel, setInvalidField: (field: NestedKeyOf<Vessel>, message: string) => void) => {
      if (!settings.name || settings.name === '') {
        setInvalidField('name', 'Cannot be empty');
      }
    },
    []
  );

  return (
    <Editor<Vessel>
      title="General"
      canDelete={false}
      item={vessel}
      validator={vesselValidator}
      initialEditMode={newMode}
      onEdited={vesselEdited}
      onCancelled={() => {
        if (newMode) {
          console.log('Cancel new vessel');
        }
      }}
      editor={(vessel, vesselChanged, validator) => (
        <VesselFieldEditor vessel={vessel} vesselChanged={vesselChanged} validator={validator} />
      )}
      viewer={(vessel) => <VesselView vessel={vessel} />}
    />
  );
};

export default VesselEditor;
