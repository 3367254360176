import React, { useEffect, useCallback, useState } from 'react';
import { AppPluginMeta, PluginConfigPageProps } from '@grafana/data';
import { Button, Field, HorizontalGroup, Input, Legend } from '@grafana/ui';
import { EcomateCompactAppSettings } from '../module';
import useSettings from 'setting-hooks';

interface Props {
  disabled?: boolean;
  enabled?: boolean;
  onEnable: () => void;
  onUpdate: () => void;
  onDisable: () => void;
}

export const ConfigActions: React.FC<Props> = ({ disabled, enabled, onDisable, onEnable, onUpdate }) => {
  const getAction = useCallback(() => {
    if (!enabled) {
      return (
        <Button variant="primary" onClick={onEnable} disabled={disabled}>
          Enable plugin
        </Button>
      );
    }

    return (
      <HorizontalGroup>
        <Button variant="primary" onClick={onUpdate}>
          Update
        </Button>
        <Button variant="destructive" onClick={onDisable}>
          Disable
        </Button>
      </HorizontalGroup>
    );
  }, [enabled, disabled, onEnable, onUpdate, onDisable]);

  return <>{getAction()}</>;
};

interface PluginConfigProps extends PluginConfigPageProps<AppPluginMeta<EcomateCompactAppSettings>> {}

const PluginConfig = (props: PluginConfigProps) => {
  const { plugin } = props;
  const [settings, updateSettings, enablePlugin, disablePlugin] = useSettings<EcomateCompactAppSettings>(
    plugin.meta.id
  );

  const [selectedDatasource, setSelectedDatasource] = useState<string>();

  useEffect(() => setSelectedDatasource(settings?.datasource), [settings]);

  const getSettings = () => {
    return {
      ...settings,
      datasource: selectedDatasource,
    };
  };
  return (
    <>
      <Legend>Settings</Legend>
      <Field label="Data source">
        <Input
          onChange={(v) => setSelectedDatasource(v.currentTarget.value)}
          placeholder="Enter datasource (f.ex: https://api.ecomate.cloud)"
          value={selectedDatasource}
          // @ts-ignore
          css={undefined}
        />
      </Field>
      <ConfigActions
        disabled={selectedDatasource === undefined || selectedDatasource === ''}
        enabled={plugin.meta.enabled}
        onDisable={disablePlugin}
        onEnable={() => enablePlugin(getSettings())}
        onUpdate={() => updateSettings(getSettings())}
      />
    </>
  );
};

export default PluginConfig;
