import React, { useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GetVesselByIdInput, GetVesselByIdQuery, GetVesselsResult } from 'graphql/queries';
import { useHistory, useParams } from 'react-router-dom';
import { Alert, Button, Card, HorizontalGroup, Label, Spinner, VerticalGroup } from '@grafana/ui';
import { formatScuId, showScuAssignmentError } from 'utils/misc';
import { useAppNotification } from 'notifications';
import { Vessel } from 'types';
import {
  ReplaceVesselScuInput,
  ReplaceVesselScuMutation,
  ReplaceVesselScuResult,
  UpdateVesselInput,
  UpdateVesselMutation,
  UpdateVesselResult,
} from 'graphql/mutations';
import ConfigView from 'components/ConfigView';
import VesselEditor from 'components/VesselEditor';
import ReplaceScu from 'components/ReplaceScu';
import ScuLastSeenTag from 'components/ScuLastSeenTag';
import { DateTime } from 'luxon';

const VesselPage = (props: { baseUrl: string }) => {
  const { baseUrl } = props;
  const { vesselid } = useParams<{ vesselid: string }>();
  const history = useHistory();
  const notifyApp = useAppNotification();
  const { loading, error, data } = useQuery<GetVesselsResult, GetVesselByIdInput>(GetVesselByIdQuery, {
    variables: {
      vesselid: vesselid,
    },
    fetchPolicy: 'no-cache',
  });
  const [updateVesselMutation] = useMutation<UpdateVesselResult, UpdateVesselInput>(UpdateVesselMutation);
  const [replaceVesselScu, replaceVesselScuStatus] = useMutation<ReplaceVesselScuResult, ReplaceVesselScuInput>(
    ReplaceVesselScuMutation
  );

  useEffect(() => {
    const scuAssignmentError = replaceVesselScuStatus.data?.replaceVesselScu.scuAssignmentError;
    showScuAssignmentError(notifyApp, scuAssignmentError);
  }, [replaceVesselScuStatus.data, notifyApp]);

  const updateVessel = async (vessel: Vessel) => {
    const result = await updateVesselMutation({
      variables: {
        input: {
          vesselId: vesselid,
          vesselName: vessel.name!,
          metadata: {
            imo: vessel.imo,
            mMSI: vessel.mMSI,
            breadth: vessel.breadth,
            deadweight: vessel.deadweight,
            flag: vessel.flag,
            grossTonnage: vessel.grossTonnage,
            length: vessel.length,
            operatingStatus: vessel.operatingStatus,
            vesselType: vessel.vesselType,
            yearBuilt: vessel.yearBuilt,
          },
        },
      },
      refetchQueries: [GetVesselByIdQuery],
      awaitRefetchQueries: true,
    });
    if (!result.data) {
      notifyApp.error('Error saving', 'Could not save changes, please try again.');
      return false;
    }
    notifyApp.success('Data saved', 'Updated vessel data successfully saved.');
    return true;
  };

  if (error) {
    return (
      <Alert severity="error" title="Error loading">
        Could not load data, try refreshing page.
      </Alert>
    );
  }
  if (loading) {
    return (
      <HorizontalGroup justify="center">
        <Spinner />
        Loading...
      </HorizontalGroup>
    );
  }
  if (!data || data.vessels.length !== 1) {
    return (
      <Alert severity="error" title="Not found">
        The vessel was not found
      </Alert>
    );
  }

  const vessel = data.vessels[0];
  const missingConfig = vessel.activeConfig?.status === 'NONE';
  const activeConfig = vessel.activeConfig;
  const draftConfig = vessel.draftConfig;
  const utcTime = DateTime.utc();
  return (
    <>
      <h3>Vessel details ({vessel.name})</h3>
      {missingConfig && (
        <Alert severity="warning" title="Not configured">
          This vessel is not configured
        </Alert>
      )}
      {!vessel.scu && (
        <Alert severity="warning" title="No SCU assigned">
          This vessel does not have an SCU assigned.
        </Alert>
      )}
      <VesselEditor vessel={vessel} vesselEdited={updateVessel} />
      <Card>
        <Card.Heading>Configuration</Card.Heading>
        <Card.Description>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))',
            }}
          >
            <VerticalGroup>
              <ConfigView
                config={activeConfig?.config}
                configState={activeConfig?.status === 'PUBLISHING' ? 'publishing' : 'published'}
                noConfigText="This vessel does not have a published configuration. To get started, create and publish a draft configuration."
              />
              {activeConfig && !draftConfig && (
                <Button key="settings" variant="primary" onClick={() => history.push(`${baseUrl}/config/${vessel.id}`)}>
                  View configuration
                </Button>
              )}
            </VerticalGroup>
            <VerticalGroup>
              <ConfigView
                config={draftConfig}
                configState="draft"
                noConfigText={
                  activeConfig ? 'No draft configuration' : 'Click Configure to start the configuration process'
                }
              />
              {(!activeConfig || draftConfig) && (
                <Button key="settings" variant="primary" onClick={() => history.push(`${baseUrl}/config/${vessel.id}`)}>
                  Configure
                </Button>
              )}
            </VerticalGroup>
          </div>
        </Card.Description>
        <Card.Actions></Card.Actions>
      </Card>
      <Card>
        <Card.Heading>SCU</Card.Heading>
        <Card.Description>
          {vessel.scu ? (
            <div
              style={{ display: 'grid', gridGap: '8px', gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))' }}
            >
              <Label description={formatScuId(vessel.scu.id)}>ID</Label>
              <Label description={vessel.scu.status}>Status</Label>
              <Label description={<ScuLastSeenTag utcTime={utcTime} vessel={vessel} />}>Last Seen</Label>
            </div>
          ) : (
            <>No SCU is assigned to this vessel</>
          )}
        </Card.Description>
        <Card.Actions>
          <ReplaceScu
            scu={vessel.scu}
            onReplace={async (scuId) => {
              const result = await replaceVesselScu({
                variables: {
                  input: {
                    scuId: scuId.replace(/:/g, ''),
                    vesselId: vesselid,
                  },
                },
                refetchQueries: [GetVesselByIdQuery],
                awaitRefetchQueries: true,
              });
              if (!result.data?.replaceVesselScu?.scuAssignmentError) {
                notifyApp.success('SCU Replaced', 'SCU was successfully replaced');
                return true;
              }
              return false;
            }}
          />
          {/* TODO: Decide if needed and implement or remove... */}
          {/* {vessel.scu && (
            <Tooltip content="Remove the SCU from this vessel (f.ex. if the vessel is decommissioned and the SCU should freed to be used on another)">
              <Button key="settings" variant="destructive" onClick={() => {}}>
                Remove SCU
              </Button>
            </Tooltip>
          )} */}
        </Card.Actions>
      </Card>
    </>
  );
};

export default VesselPage;
