import { Label } from '@grafana/ui';
import React from 'react';
import { Vessel } from 'types';

const VesselView = (props: { vessel: Vessel }) => {
  const { vessel } = props;
  return (
    <div style={{ display: 'grid', gridGap: '8px', gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))' }}>
      <Label description={vessel.name}>Name</Label>
      <Label description={vessel.imo ?? '---'}>IMO</Label>
      <Label description={vessel.mMSI ?? '---'}>MMSI</Label>
      <Label description={vessel.vesselType ?? '---'}>Vessel Type</Label>
      <Label description={vessel.operatingStatus ?? '---'}>Operating Status</Label>
      <Label description={vessel.flag ?? '---'}>Flag</Label>
      <Label description={vessel.grossTonnage ?? '---'}>Gross Tonnage</Label>
      <Label description={vessel.deadweight ?? '---'}>Dead Weight</Label>
      <Label description={vessel.length ?? '---'}>Length [m]</Label>
      <Label description={vessel.breadth ?? '---'}>Breadth [m]</Label>
      <Label description={vessel.yearBuilt ?? '---'}>Year Built</Label>
    </div>
  );
};

export default VesselView;
