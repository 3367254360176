export enum ConsumerMode {
  INLET = 'INLET',
  LOOP = 'LOOP',
}

export enum ConsumerType {
  MAINENGINE = 'MAIN_ENGINE',
  AUXENGINE = 'AUX_ENGINE',
  OTHER = 'OTHER',
}

export interface ConsumerConfig {
  cutOff: number | undefined;
  minimumConsumption?: number;
  maximumConsumption?: number;
  type?: string; // Main, Aux, etc
  id: string;
  inletFlowmeterId: string | undefined;
  mode: ConsumerMode;
  name: string;
  outletFlowmeterId: string | undefined;
}

export enum QuadRegisterOrder {
  R1R2R3R4 = 'R1R2R3R4',
  R2R1R4R3 = 'R2R1R4R3',
  R3R4R1R2 = 'R3R4R1R2',
  R4R3R2R1 = 'R4R3R2R1',
}

export enum DoubleRegisterOrder {
  R1R2 = 'R1R2',
  R2R1 = 'R2R1',
}

export interface ModbusSettings {
  doubleRegisterOrder: DoubleRegisterOrder;
  quadRegisterOrder: QuadRegisterOrder;
  slaveAddress: number;
}

export enum FlowmeterFamily {
  Optimass = 'OPTIMASS',
  Flowsonic = 'FLOWSONIC',
}

export enum FlowsonicModel {
  I400 = 'I400',
  I450 = 'I450',
  GT = 'GT',
}

export enum OptimassConverterType {
  MFC010 = 'MFC010',
  MFC300 = 'MFC300',
  MFC400ER1 = 'MFC400ER1',
  MFC400ER2 = 'MFC400ER2',
  MFC400ER2_1_4 = 'MFC400ER2_1_4',
}

export enum OptimassType {
  OM1000_S15 = 'OM1000-S15',
  OM1000_S25 = 'OM1000-S25',
  OM6000_S08 = 'OM6000-S08',
  OM6000_S10 = 'OM6000-S10',
  OM6000_S15 = 'OM6000-S15',
  OM6000_S25 = 'OM6000-S25',
}

export interface OptimassSettings {
  converter: OptimassConverterType;
  model: string;
}

export interface FlowsonicSettings {
  model: FlowsonicModel;
  serialNumber?: number;
}

export interface FlowmeterConfig {
  id: string;
  name: string;
  modbusSettings: ModbusSettings;
  family: FlowmeterFamily;
  optimassSettings?: OptimassSettings;
  flowsonicSettings?: FlowsonicSettings;
}

export interface ModbusTcpSettings {
  doubleRegisterOrder: DoubleRegisterOrder;
  quadRegisterOrder: QuadRegisterOrder;
  enabled: boolean;
  port: number;
}

export interface NmeaSettings {
  enabled: boolean;
}

export interface EcomateConfig {
  consumers: ConsumerConfig[];
  flowmeters: FlowmeterConfig[];
  modbusTcp: ModbusTcpSettings;
  nmea: NmeaSettings;
  maximumConsumption?: number;
  maximumSpeed?: number;
}

export interface VesselMetadata {
  imo?: string;
  mMSI?: string;
  vesselType?: string;
  operatingStatus?: string;
  flag?: string;
  grossTonnage?: number;
  deadweight?: number;
  length?: number;
  breadth?: number;
  yearBuilt?: number;
}

export interface Vessel extends VesselMetadata {
  id: string;
  name?: string;
  swVersion?: string;
  class?: string;
  ecomateType: 'STANDARD' | 'COMPACT';
  activeConfig?: ActiveConfig;
  draftConfig?: ScuConfig;
  oldConfigs?: ScuConfig[];
  scuId?: string;
  scu?: Scu;
}

export interface ActiveConfig {
  config?: ScuConfig;
  status: 'NONE' | 'PUBLISHED' | 'PUBLISHING';
}

export interface ScuConfig {
  comment: string;
  config: EcomateConfig;
  draft: boolean;
  created: string;
  hash: string;
  id?: number;
  vesselId?: string;
  vessel?: Vessel;
}

export interface Scu {
  id: string;
  vesselId: string;
  firstSeen: string;
  lastSeen: string;
  status: 'ASSIGNED' | 'PENDING' | 'UNASSIGNED';
}

export type NewOrExisting<T> = T & { isNew?: boolean };

type KeyOf<ObjectType extends {}> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends {} ? `${Key}` : `${Key}`;
}[keyof ObjectType & (string | number)];

type NestedKeyOfInternal<ObjectType extends {}> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends {}
    ? `${Key}` | `${Key}.${KeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

// The below is needed to avoid TS2345 error (https://github.com/microsoft/TypeScript/issues/26627)
export type NestedKeyOf<T> = T extends {} ? NestedKeyOfInternal<T> : never;
