import { gql } from '@apollo/client';

export const EcomateConfigurationFields = gql`
  fragment EcomateConfigurationFields on EcomateConfiguration {
    consumers {
      cutOff
      id
      inletFlowmeterId
      mode
      name
      type
      outletFlowmeterId
      minimumConsumption
      maximumConsumption
    }
    flowmeters {
      family
      id
      modbusSettings {
        doubleRegisterOrder
        quadRegisterOrder
        slaveAddress
      }
      name
      optimassSettings {
        converter
        model
      }
      flowsonicSettings {
        model
        serialNumber
      }
    }
    maximumSpeed
    maximumConsumption
    hash
    modbusTcp {
      doubleRegisterOrder
      quadRegisterOrder
      enabled
      port
    }
    nmea {
      enabled
    }
  }
`;

export const ScuConfigFields = gql`
  ${EcomateConfigurationFields}
  fragment ScuConfigFields on ScuConfig {
    comment
    created
    draft
    hash
    id
    vesselId
    config {
      ...EcomateConfigurationFields
    }
  }
`;

export const VesselFields = gql`
  fragment VesselFields on Vessel {
    id
    name
    imo
    swVersion
    class
    ecomateType
    vesselType
    flag
    grossTonnage
    deadweight
    length
    breadth
    yearBuilt
    scu {
      id
      vesselId
      firstSeen
      lastSeen
      status
    }
  }
`;
