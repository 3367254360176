import { getBackendSrv } from '@grafana/runtime';

export async function enablePlugin<T>(pluginId: string, settings: T) {
  const pluginSettings = await getBackendSrv().get(`api/plugins/${pluginId}/settings`);
  await getBackendSrv().post(`api/plugins/${pluginId}/settings`, {
    ...pluginSettings,
    jsonData: {
      ...(pluginSettings.jsonData ?? {}),
      ...settings,
    },
    enabled: true,
    pinned: true,
  });
}

export async function disablePlugin(pluginId: string) {
  await getBackendSrv().post(`api/plugins/${pluginId}/settings`, {
    data: {
      enabled: false,
      pinned: false,
    },
  });
}
