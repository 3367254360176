import React, { useState, useEffect } from 'react';
import { Button, Card, ConfirmModal } from '@grafana/ui';
import _ from 'lodash';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

import { FlowmeterConfig, ConsumerConfig, NewOrExisting, ConsumerMode } from 'types';
import EditConsumer from './EditConsumer';
import { createGuid } from 'utils/misc';
import ScrollIntoView from './ScrollIntoView';

const EditConsumers = (props: {
  consumers: ConsumerConfig[];
  flowmeters: FlowmeterConfig[];
  onChanged: (consumers: NewOrExisting<ConsumerConfig[]>) => void;
}) => {
  const { flowmeters, onChanged } = props;
  // Local state for the props.consumers is needed due to drag'n'drop functionality
  // This way the local state can be updated immediately when dropping (moving) an item
  // otherwise it would jump since the props.consumers is only changed after the call to the API
  const [consumers, setConsumers] = useState<Array<NewOrExisting<ConsumerConfig>>>(props.consumers);
  const [consumerToDelete, setConsumerToDelete] = useState<ConsumerConfig | undefined>(undefined);

  useEffect(() => {
    setConsumers(props.consumers);
  }, [props.consumers]);

  const consumerEdited = (consumer: NewOrExisting<ConsumerConfig>) => {
    if (consumer.isNew) {
      consumer.isNew = false;
    }
    onChanged(consumers.map((c) => (c.id === consumer.id ? consumer : c)));
  };

  const consumerDeleted = (consumer: NewOrExisting<ConsumerConfig>) => {
    if (consumer.isNew) {
      onChanged(consumers.filter((f) => f.id !== consumer.id));
    } else {
      setConsumerToDelete(consumer);
    }
  };
  const consumerAdded = () => {
    onChanged([
      ...consumers,
      {
        id: createGuid(),
        isNew: true,
        cutOff: undefined,
        inletFlowmeterId: undefined,
        outletFlowmeterId: undefined,
        mode: ConsumerMode.INLET,
        name: '',
      },
    ]);
  };
  const dragEnd = (result: DropResult) => {
    if (result.destination) {
      const index = result.source.index;
      const newIndex = result.destination.index;
      const newArray = [...consumers];
      newArray.splice(newIndex, 0, newArray.splice(index, 1)[0]);
      setConsumers(newArray);
      onChanged(newArray);
    }
  };
  return (
    <div>
      <ConfirmModal
        isOpen={!!consumerToDelete}
        onConfirm={() => {
          if (consumerToDelete) {
            onChanged(consumers.filter((f) => f.id !== consumerToDelete.id));
          }
          setConsumerToDelete(undefined);
        }}
        onDismiss={() => setConsumerToDelete(undefined)}
        title="Delete?"
        body="This will delete flowmeter"
        confirmText="Ok"
      />
      <Card>
        <Card.Description>
          Below is the configured consumers. Use the edit button to edit and drag to move.
        </Card.Description>
        <Card.Actions>
          <Button key="settings" variant="primary" onClick={consumerAdded}>
            Add consumer
          </Button>
        </Card.Actions>
      </Card>
      <DragDropContext onDragEnd={dragEnd}>
        <Droppable droppableId="consumers">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {consumers.map((consumer, index) => (
                <ScrollIntoView key={consumer.id} shouldScrollIntoView={!!consumer.isNew}>
                  <EditConsumer
                    consumer={consumer}
                    consumers={consumers}
                    flowmeters={flowmeters}
                    onEdited={consumerEdited}
                    onDeleted={consumerDeleted}
                    draggableId={consumer.id}
                    index={index}
                  />
                </ScrollIntoView>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default EditConsumers;
