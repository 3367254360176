import { gql } from '@apollo/client';
import { ScuConfig, Vessel } from 'types';
import { EcomateConfigurationFields, ScuConfigFields, VesselFields } from './fragments';

export const GetVesselsQuery = gql`
  ${VesselFields}
  ${ScuConfigFields}
  query GetVessels {
    vessels(where: { ecomateType: { eq: COMPACT } }, order: { name: ASC }) {
      ...VesselFields
      activeConfig {
        config {
          ...ScuConfigFields
        }
        status
      }
      draftConfig {
        ...ScuConfigFields
      }
    }
  }
`;

export interface GetVesselsResult {
  vessels: Vessel[];
}

export const GetVesselByIdQuery = gql`
  ${VesselFields}
  ${ScuConfigFields}
  query GetVesselsById($vesselid: String) {
    vessels(where: { id: { eq: $vesselid }, and: { ecomateType: { eq: COMPACT } } }) {
      ...VesselFields
      activeConfig {
        config {
          ...ScuConfigFields
        }
        status
      }
      draftConfig {
        ...ScuConfigFields
      }
    }
  }
`;

export interface GetVesselByIdInput {
  vesselid: string;
}

export interface GetConfigResult {
  scuConfigs: ScuConfig[];
}
export interface GetConfigByIdInput {
  configid: number;
}

export const GetConfigQuery = gql`
  ${EcomateConfigurationFields}
  query GetScuConfigs {
    scuConfigs {
      active
      comment
      activeConfig {
        ...EcomateConfigurationFields
      }
      draftConfig {
        ...EcomateConfigurationFields
      }
      created
      hash
      id
      vesselId
      vessel {
        name
      }
    }
  }
`;

export const GetConfigByIdQuery = gql`
  ${EcomateConfigurationFields}
  query GetScuConfigById($configid: Int) {
    scuConfigs(where: { id: { eq: $configid } }) {
      active
      comment
      activeConfig {
        ...EcomateConfigurationFields
      }
      draftConfig {
        ...EcomateConfigurationFields
      }
      created
      hash
      id
      vesselId
      vessel {
        name
      }
    }
  }
`;
