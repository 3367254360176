import React, { useState } from 'react';

const ScrollIntoView = (props: { shouldScrollIntoView: boolean; children: JSX.Element }) => {
  const { shouldScrollIntoView, children } = props;
  const [hasScrolled, setHasScrolled] = useState(false);
  return shouldScrollIntoView ? (
    <div
      ref={(ref) => {
        if (!hasScrolled && ref) {
          setHasScrolled(true);
          ref.scrollIntoView({ behavior: 'smooth' });
        }
      }}
    >
      {children}
    </div>
  ) : (
    children
  );
};

export default ScrollIntoView;
