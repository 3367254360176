import React, { useState, useEffect } from 'react';
import { Button, Card, ConfirmModal } from '@grafana/ui';
import _ from 'lodash';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

import {
  DoubleRegisterOrder,
  FlowmeterConfig,
  QuadRegisterOrder,
  NewOrExisting,
  FlowmeterFamily,
  FlowsonicModel,
} from 'types';
import EditFlowmeter from './EditFlowmeter';
import { createGuid } from 'utils/misc';
import ScrollIntoView from './ScrollIntoView';

const EditFlowmeters = (props: {
  flowmeters: FlowmeterConfig[];
  onChanged: (flowmeters: Array<NewOrExisting<FlowmeterConfig>>) => void;
}) => {
  const { onChanged } = props;
  // Local state for the props.flowmeters is needed due to drag'n'drop functionality
  // This way the local state can be updated immediately when dropping (moving) an item
  // otherwise it would jump since the props.flowmeters is only changed after the call to the API
  const [flowmeters, setFlowmeters] = useState<Array<NewOrExisting<FlowmeterConfig>>>(props.flowmeters);
  const [flowmeterToDelete, setFlowmeterToDelete] = useState<FlowmeterConfig | undefined>(undefined);

  useEffect(() => {
    setFlowmeters(props.flowmeters);
  }, [props.flowmeters]);

  const flowmeterEdited = (flowmeter: NewOrExisting<FlowmeterConfig>) => {
    if (flowmeter.isNew) {
      flowmeter.isNew = false;
    }
    onChanged(flowmeters.map((f) => (f.id === flowmeter.id ? flowmeter : f)));
  };

  const flowmeterDeleted = (flowmeter: NewOrExisting<FlowmeterConfig>) => {
    if (flowmeter.isNew) {
      onChanged(flowmeters.filter((f) => f.id !== flowmeter.id));
    } else {
      setFlowmeterToDelete(flowmeter);
    }
  };

  const getNextSlaveAddress = () => {
    const usedAddresses = flowmeters
      .filter(f=> !(f.family === FlowmeterFamily.Flowsonic && f.flowsonicSettings?.model === FlowsonicModel.I450))
      .map(f=>f.modbusSettings.slaveAddress);

    let address = 1;
    while (usedAddresses.find(a => a === address))
    {
      address++;
    }
    return address;
  }

  const flowmeterAdded = () => {
    onChanged([
      ...flowmeters,
      {
        id: createGuid(),
        isNew: true,
        family: FlowmeterFamily.Flowsonic,
        modbusSettings: {
          doubleRegisterOrder: DoubleRegisterOrder.R2R1,
          quadRegisterOrder: QuadRegisterOrder.R4R3R2R1,
          slaveAddress: getNextSlaveAddress(),
        },
        name: '',
        flowsonicSettings: {
          model: FlowsonicModel.I450,
        },
      },
    ]);
  };
  const dragEnd = (result: DropResult) => {
    if (result.destination) {
      const index = result.source.index;
      const newIndex = result.destination.index;
      const newArray = [...flowmeters];
      newArray.splice(newIndex, 0, newArray.splice(index, 1)[0]);
      setFlowmeters(newArray);
      onChanged(newArray);
    }
  };
  return (
    <div>
      <ConfirmModal
        isOpen={!!flowmeterToDelete}
        onConfirm={() => {
          if (flowmeterToDelete) {
            onChanged(flowmeters.filter((f) => f.id !== flowmeterToDelete.id));
          }
          setFlowmeterToDelete(undefined);
        }}
        onDismiss={() => setFlowmeterToDelete(undefined)}
        title="Delete?"
        body="This will delete flowmeter"
        confirmText="Ok"
      />
      <Card>
        <Card.Description>
          Below is the configured flowmeters. Use the edit button to edit and drag to move.
        </Card.Description>
        <Card.Actions>
          <Button key="settings" variant="primary" onClick={flowmeterAdded}>
            Add flowmeter
          </Button>
        </Card.Actions>
      </Card>
      <DragDropContext onDragEnd={dragEnd}>
        <Droppable droppableId="flowmeters">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {flowmeters.map((flowmeter, index) => (
                <ScrollIntoView key={flowmeter.id} shouldScrollIntoView={!!flowmeter.isNew}>
                  <EditFlowmeter
                    flowmeter={flowmeter}
                    onEdited={flowmeterEdited}
                    onDeleted={flowmeterDeleted}
                    draggableId={flowmeter.id}
                    index={index}
                  />
                </ScrollIntoView>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default EditFlowmeters;
