import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Alert,
  FilterInput,
  HorizontalGroup,
  LinkButton,
  Pagination,
  Spinner,
  Tag,
  Tooltip,
  VerticalGroup,
} from '@grafana/ui';
import { Link } from 'react-router-dom';
import { GetVesselsQuery, GetVesselsResult } from '../graphql/queries';
import { formatScuId } from 'utils/misc';
import { Vessel } from 'types';
import Fuse from 'fuse.js';
import _ from 'lodash';
import { DateTime } from 'luxon';
import ScuLastSeenTag from 'components/ScuLastSeenTag';

const VesselsTable = (props: { baseUrl: string; vessels: Vessel[] }) => {
  const { baseUrl, vessels } = props;
  const getLink = (vessel: Vessel) => `${baseUrl}/vessel/${vessel.id}`;
  const getCell = (maxWidth: number, vessel: Vessel, content: JSX.Element | string | undefined) => {
    return (
      <td className={`max-width-${maxWidth} link-td`}>
        <Link to={getLink(vessel)}>
          <span className="ellipsis">{content}</span>
        </Link>
      </td>
    );
  };
  const utcTime = DateTime.utc();
  const getLastSeenCell = (vessel: Vessel) => {
    return getCell(2, vessel, <ScuLastSeenTag utcTime={utcTime} vessel={vessel} />);
  };
  const getConfigCell = (maxWidth: number, vessel: Vessel) => {
    const tooltip =
      vessel.activeConfig?.status === 'NONE'
        ? 'The vessel has not been configured'
        : vessel.activeConfig?.status === 'PUBLISHED'
        ? 'The vessel is configured and uses the latest configuration'
        : 'The latest configuration has not yet been taken in use by the vessel (will normally be updated in a few minutes when SCU is online)';
    return getCell(
      maxWidth,
      vessel,
      <Tooltip content={tooltip}>
        <Tag
          name={_.startCase(vessel.activeConfig?.status)}
          colorIndex={vessel.activeConfig?.status === 'NONE' ? 7 : vessel.activeConfig?.status === 'PUBLISHED' ? 4 : 9}
        />
      </Tooltip>
    );
  };
  return (
    <table className="filter-table filter-table--hover form-inline">
      <thead>
        <tr>
          <th>Name</th>
          <th>IMO</th>
          <th>MMSI</th>
          <th>Configuration</th>
          <th>Last Seen</th>
          <th>SCU</th>
        </tr>
      </thead>
      <tbody>
        {vessels.map((vessel) => {
          return (
            <tr key={`${vessel.id}`}>
              {getCell(5, vessel, vessel.name)}
              {getCell(3, vessel, vessel.imo)}
              {getCell(3, vessel, vessel.mMSI)}
              {getConfigCell(5, vessel)}
              {getLastSeenCell(vessel)}
              {getCell(4, vessel, vessel.scu?.id ? formatScuId(vessel.scu.id) : '-')}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const Vessels = (props: { baseUrl: string }) => {
  const { baseUrl } = props;
  const { loading, error, data } = useQuery<GetVesselsResult>(GetVesselsQuery, { fetchPolicy: 'no-cache' });

  const pageSize = 20;

  const [page, setPage] = useState(1);
  const [vesselSearch, setVesselSearch] = useState('');

  if (loading) {
    return (
      <HorizontalGroup justify="center">
        <Spinner />
        Loading...
      </HorizontalGroup>
    );
  }
  if (error || !data) {
    return (
      <Alert severity="error" title="Error loading">
        Could not load data, try refreshing page.
      </Alert>
    );
  }

  const totalPages = Math.ceil(data.vessels.length / pageSize);
  const offset = (page - 1) * pageSize;
  const options = {
    keys: ['name', 'vesselType'],
  };

  const fuse = new Fuse(data.vessels, options);
  const filteredVessels = vesselSearch !== '' ? fuse.search(vesselSearch).map((i) => i.item) : data.vessels;
  const vessels = filteredVessels.slice(offset, offset + pageSize);
  return (
    <>
      <div className="page-action-bar">
        <div className="gf-form gf-form--grow">
          <FilterInput value={vesselSearch} onChange={setVesselSearch} placeholder="Search for vessel" />
        </div>
        <LinkButton href={`${baseUrl}/new-vessel`}>Add vessel</LinkButton>
      </div>
      <VerticalGroup spacing="md">
        <VesselsTable vessels={vessels} baseUrl={baseUrl} />
        <HorizontalGroup justify="flex-end">
          <Pagination onNavigate={setPage} currentPage={page} numberOfPages={totalPages} hideWhenSinglePage={true} />
        </HorizontalGroup>
      </VerticalGroup>
    </>
  );
};

export default Vessels;
