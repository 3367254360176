import React, { useState, useEffect, useCallback } from 'react';
import { useAppNotification } from 'notifications';
import { useMutation } from '@apollo/client';
import { NestedKeyOf, Vessel } from 'types';
import { AddVesselMutation, AddVesselResult, AddVesselInputPayload, AddVesselInput } from '../graphql/mutations';
import { useHistory } from 'react-router-dom';
import { isScuValid, showScuAssignmentError } from 'utils/misc';
import Editor from 'components/Editor';
import VesselFieldEditor from 'components/VesselFieldEditor';
import VesselView from 'components/VesselView';

type NewVesselState = {
  vessel: Vessel;
  addedVessel?: Vessel;
};

const NewVesselEditor = (props: {
  baseUrl: string;
  newVessel: (data: AddVesselInputPayload) => Promise<Vessel | undefined>;
}) => {
  const { baseUrl, newVessel } = props;
  const notifyApp = useAppNotification();
  const [state, setState] = useState<NewVesselState>({
    vessel: {
      id: '',
      name: '',
      ecomateType: 'COMPACT',
    },
  });
  const history = useHistory();

  const addVessel = async (vessel: Vessel) => {
    const { name, scuId, id, ecomateType, ...metadata } = vessel;
    const result = await newVessel({
      scuId: (scuId ?? '').replace(/:/g, ''),
      vesselName: name!,
      metadata: metadata,
    });
    if (result) {
      notifyApp.success('Vessel added', 'Vessel was successfully added.');
      history.push(`${baseUrl}/config/${result.id}/new`);
    }
  };

  const vesselValidator = useCallback(
    (settings: Vessel, setInvalidField: (field: NestedKeyOf<Vessel>, message: string) => void) => {
      if (!settings.name || settings.name === '') {
        setInvalidField('name', 'Cannot be empty');
      }
      if (!isScuValid(settings.scuId)) {
        setInvalidField('scuId', 'Invalid format (Valid format is xx:xx:xx:xx:xx:xx, where x is 0-9/a-f)');
      }
    },
    []
  );

  return (
    <Editor<Vessel>
      title="Enter scu/vessel data"
      canDelete={false}
      canCancel={false}
      item={state.vessel}
      validator={vesselValidator}
      initialEditMode
      onEdited={async (v) => {
        setState((state) => ({ ...state, vessel: v }));
        await addVessel(v);
        return false;
      }}
      editor={(vessel, vesselChanged, validator) => (
        <VesselFieldEditor vessel={vessel} vesselChanged={vesselChanged} validator={validator} showScuId />
      )}
      viewer={(vessel) => <VesselView vessel={vessel} />}
    />
  );
};

const NewVessel = (props: { baseUrl: string }) => {
  const { baseUrl } = props;
  const notifyApp = useAppNotification();
  const [newVessel, newVesselStatus] = useMutation<AddVesselResult, AddVesselInput>(AddVesselMutation, {
    onError: (err) => notifyApp.error('Error adding vessel', 'An error occured, please try again'),
  });

  useEffect(() => {
    const scuAssignmentError = newVesselStatus.data?.addVessel.scuAssignmentError;
    showScuAssignmentError(notifyApp, scuAssignmentError);
  }, [newVesselStatus, notifyApp]);

  return (
    <>
      <h3>Add new vessel</h3>
      <NewVesselEditor
        baseUrl={baseUrl}
        newVessel={async (payload) => {
          const result = await newVessel({
            variables: {
              input: payload,
            },
          });
          return result?.data?.addVessel.vessel;
        }}
      />
    </>
  );
};

export default NewVessel;
