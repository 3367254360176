// @ts-expect-error: The import path exist
import appEvents from 'grafana/app/core/app_events';
import { AppEvents } from '@grafana/data';
import { useMemo } from 'react';

export const useAppNotification = () => {
  return useMemo(
    () => ({
      success: (title: string, text?: string) => appEvents.emit(AppEvents.alertSuccess, [title, text]),
      warning: (title: string, text?: string) => appEvents.emit(AppEvents.alertWarning, [title, text]),
      error: (title: string, text?: string) => appEvents.emit(AppEvents.alertError, [title, text]),
    }),
    []
  );
};
