import { Label, Tag, VerticalGroup } from '@grafana/ui';
import React from 'react';
import { ScuConfig } from 'types';
import _ from 'lodash';

const ConfigView = (props: {
  config?: ScuConfig;
  configState: 'draft' | 'published' | 'publishing';
  noConfigText: string;
}) => {
  const { config, configState, noConfigText } = props;
  const colorIndex = configState === 'draft' ? 7 : configState === 'published' ? 4 : 9;
  return config ? (
    <VerticalGroup>
      <Tag name={_.startCase(configState)} colorIndex={colorIndex} />
      <Label description={config.config.nmea.enabled ? 'Enabled' : 'Disabled'}>NMEA</Label>
      <Label description={config.config.consumers.map((c) => c.name).join(', ')}>Consumers</Label>
      <Label description={config.config.flowmeters.map((c) => c.name).join(', ')}>Flowmeters</Label>
      <Label
        description={config.config.modbusTcp.enabled ? <>Enabled (Port: {config.config.modbusTcp.port})</> : 'Disabled'}
      >
        Modbus TCP
      </Label>
    </VerticalGroup>
  ) : (
    <VerticalGroup>
      <Tag name={_.capitalize(configState)} colorIndex={colorIndex} />
      <div>{noConfigText}</div>
    </VerticalGroup>
  );
};

export default ConfigView;
