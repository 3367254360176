import React, { useState } from 'react';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import { ActiveConfig, ScuConfig } from 'types';
import { Button, Card, HorizontalGroup, Input, Modal, useStyles2, VerticalGroup } from '@grafana/ui';
import ConfigView from './ConfigView';

const getStyles = (theme: GrafanaTheme2) => ({
  modal: css`
    width: 500px;
  `,
  modalText: css({
    fontSize: theme.typography.h5.fontSize,
    color: theme.colors.text.primary,
  }),
  modalDescription: css({
    fontSize: theme.typography.body.fontSize,
  }),
  modalConfirmationInput: css({
    paddingTop: theme.spacing(1),
  }),
  descriptionContent: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))',
  }),
});

const ConfigSummary = (props: {
  vessel: string;
  activeConfig?: ActiveConfig;
  draftConfig?: ScuConfig;
  onNew: () => void;
  onPublish: (comment: string) => void;
  canPublish: () => boolean;
  onDelete: () => void;
}) => {
  const styles = useStyles2(getStyles);
  const { vessel, activeConfig, draftConfig, onNew, onPublish, canPublish, onDelete } = props;
  const [modalState, setModalState] = useState({ isOpen: false, publishComment: '' });

  const close = () =>
    setModalState({
      ...modalState,
      isOpen: false,
    });

  const open = () => {
    if (canPublish()) {
      setModalState({
        publishComment: '',
        isOpen: true,
      });
    }
  };

  return (
    <>
      <Modal className={styles.modal} isOpen={modalState.isOpen} title="Confirm" onDismiss={close}>
        <div className={styles.modalText}>{`This will update the active configuration for ${vessel}.`}</div>
        <div className={styles.modalConfirmationInput}>
          <HorizontalGroup>
            <Input
              placeholder={`Enter a comment`}
              value={modalState.publishComment}
              onChange={(e) => setModalState({ ...modalState, publishComment: e.currentTarget.value })}
            />
          </HorizontalGroup>
        </div>
        <Modal.ButtonRow>
          <Button
            variant="primary"
            onClick={() => {
              close();
              onPublish(modalState.publishComment);
            }}
          >
            Ok
          </Button>
          <Button variant="secondary" onClick={close}>
            Cancel
          </Button>
        </Modal.ButtonRow>
      </Modal>
      <Card>
        <Card.Heading>Configuration for vessel: {vessel}</Card.Heading>
        <Card.Description>
          <div className={styles.descriptionContent}>
            <ConfigView
              config={activeConfig?.config}
              configState={activeConfig?.status === 'PUBLISHING' ? 'publishing' : 'published'}
              noConfigText="This vessel does not have a published configuration. To get started, create and publish a draft configuration."
            />
            <VerticalGroup>
              <ConfigView
                config={draftConfig}
                configState="draft"
                noConfigText={
                  activeConfig?.config
                    ? 'To create a draft configuration, start editing the current (Published) configuration'
                    : 'To create a draft configuration, click the New button'
                }
              />
              {draftConfig?.config && (
                <HorizontalGroup>
                  <Button key="settings" variant="primary" onClick={open}>
                    Publish
                  </Button>
                  <Button key="settings" variant="destructive" onClick={onDelete}>
                    Discard
                  </Button>
                </HorizontalGroup>
              )}
              {!draftConfig?.config && !activeConfig?.config && (
                <Button key="settings" variant="primary" onClick={onNew}>
                  New
                </Button>
              )}
            </VerticalGroup>
          </div>
        </Card.Description>
      </Card>
    </>
  );
};

export default ConfigSummary;
