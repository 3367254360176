import React, { useCallback } from 'react';
import { VerticalGroup, Field, Input, Select, Switch } from '@grafana/ui';
import { ModbusTcpSettings, QuadRegisterOrder, DoubleRegisterOrder, EcomateConfig, NestedKeyOf } from 'types';
import Editor, { EditorValidator } from './Editor';
import { optionalNum, scale } from 'utils/misc';

const SettingsViewer = (props: { item: ModbusTcpSettings }) => {
  const { item } = props;
  return item.enabled ? (
    <VerticalGroup>
      <>Enabled</>
      <>TCP Port: {item.port}</>
      <>
        Register order: {item.quadRegisterOrder} / {item.doubleRegisterOrder}
      </>
    </VerticalGroup>
  ) : (
    <>Disabled</>
  );
};

const Settings = (props: {
  item: ModbusTcpSettings;
  onEdited: (item: ModbusTcpSettings) => void;
  validator: EditorValidator<ModbusTcpSettings>;
}) => {
  const { item, onEdited, validator } = props;
  const modbusTcp = item;
  return (
    <div style={{ display: 'grid', gridGap: '8px', gridTemplateColumns: 'auto auto' }}>
      <Field label="Enable">
        <Switch value={modbusTcp.enabled} onChange={(e) => onEdited({ ...modbusTcp, enabled: !modbusTcp.enabled })} />
      </Field>
      <div style={{ alignSelf: 'center' }}>
        Enables the Modbus TCP Slave of the SCU. Used to provide data to external systems.
      </div>
      <Field label="TCP Port" invalid={validator.isFieldInvalid('port')} error={validator.fieldError('port')}>
        <Input
          name="mbslave"
          type="number"
          onBlur={() => validator.validate('port')}
          value={modbusTcp.port}
          onChange={(e) => onEdited({ ...modbusTcp, port: +e.currentTarget.value })}
        />
      </Field>
      <div style={{ alignSelf: 'center' }}>
        The TCP port of the Modbus Slave.
        <br />
        The default port used for Modbus TCP is 502.
      </div>
      <Field label="Quad Register order">
        <Select
          menuShouldPortal
          options={Object.keys(QuadRegisterOrder).map((key) => {
            return { value: key, label: key };
          })}
          value={modbusTcp.quadRegisterOrder}
          onChange={(e) =>
            onEdited({
              ...modbusTcp,
              quadRegisterOrder: e.value as QuadRegisterOrder,
            })
          }
        />
      </Field>
      <div style={{ alignSelf: 'center' }}>
        The register order for values containing 4 registers, default is R4R3R2R1.
        <br />
        If incorrect, will cause incorrect readings. Consult the usermanual for further details.
      </div>
      <Field label="Double Register order">
        <Select
          menuShouldPortal
          options={Object.keys(DoubleRegisterOrder).map((key) => {
            return { value: key, label: key };
          })}
          value={modbusTcp.doubleRegisterOrder}
          onChange={(e) =>
            onEdited({
              ...modbusTcp,
              doubleRegisterOrder: e.value as DoubleRegisterOrder,
            })
          }
        />
      </Field>
      <div style={{ alignSelf: 'center' }}>
        The register order for values containing 2 registers, default is R2R1.
        <br />
        If incorrect, will cause incorrect readings. Consult the usermanual for further details.
      </div>
    </div>
  );
};

const GeneralEdit = (props: {
  config: EcomateConfig;
  onEdited: (config: EcomateConfig) => void;
  validator: EditorValidator<EcomateConfig>;
}) => {
  const { config, onEdited, validator } = props;

  return (
    <div style={{ display: 'grid', gridGap: '8px', gridTemplateColumns: 'auto auto' }}>
      <Field label="Enable">
        <Switch
          value={config.nmea.enabled}
          onChange={(e) => onEdited({ ...config, nmea: { ...config.nmea, enabled: !config.nmea.enabled } })}
        />
      </Field>
      <div style={{ alignSelf: 'center' }}>
        Enables reading of NMEA input. Requires NMEA to be connected to the SCU.
        <br />
        Provides functionality for monitoring the speed, performance and position of the vessel.
      </div>
      <Field
        label="Maximum Speed [knots]"
        invalid={validator.isFieldInvalid('maximumSpeed')}
        error={validator.fieldError('maximumSpeed')}
      >
        <Input
          name="maximumSpeed"
          type="number"
          value={scale(config.maximumSpeed, 1.94384449)}
          onBlur={() => validator.validate('maximumSpeed')}
          onChange={(e) =>
            onEdited({ ...config, maximumSpeed: scale(optionalNum(e.currentTarget.value), 1 / 1.94384449) })
          }
        />
      </Field>
      <div style={{ alignSelf: 'center' }}>
        The maximum speed for this vessel. Used on gauges, and should normally be rounded up to the nearest number.
        <br />
        F.ex. if expected maximum speed is 17 knots it can be set to 20 knots.
      </div>
      <Field
        label="Maximum Consumption [kg/h]"
        invalid={validator.isFieldInvalid('maximumConsumption')}
        error={validator.fieldError('maximumConsumption')}
      >
        <Input
          name="maximumConsumption"
          type="number"
          value={scale(config.maximumConsumption, 3600)}
          onBlur={() => validator.validate('maximumConsumption')}
          onChange={(e) =>
            onEdited({ ...config, maximumConsumption: scale(optionalNum(e.currentTarget.value), 1 / 3600) })
          }
        />
      </Field>
      <div style={{ alignSelf: 'center' }}>
        The maximum expected total consumption for this vessel. Used on gauges, and should normally be rounded up to the
        nearest number.
        <br />
        F.ex. if expected maximum total consumption is 355 kg/h it can be set to 400 kg/h.
      </div>
    </div>
  );
};

const GeneralView = (props: { config: EcomateConfig }) => {
  const { config } = props;
  return (
    <VerticalGroup>
      <>
        NMEA:{' '}
        {config.nmea.enabled
          ? 'Enabled (Reads position and speed data from NMEA for use in EcoMATE)'
          : 'Disabled (No position or speed data available in EcoMATE'}
      </>
      <>Maximum Speed [knots]: {config.maximumSpeed ? scale(config.maximumSpeed, 1.94384449) : '---'}</>
      <>Maximum Consumption [kg/h]: {scale(config.maximumConsumption, 3600) ?? '---'}</>
    </VerticalGroup>
  );
};

const GeneralSettings = (props: { config: EcomateConfig; onChanged: (config: EcomateConfig) => void }): JSX.Element => {
  const { config, onChanged } = props;
  const changed = (item: ModbusTcpSettings) => {
    onChanged({ ...config, modbusTcp: item });
  };

  const vesselValidator = useCallback(
    (settings: EcomateConfig, setInvalidField: (field: NestedKeyOf<EcomateConfig>, message: string) => void) => {
      if (typeof settings.maximumSpeed === 'number' && settings.maximumSpeed <= 0) {
        setInvalidField('maximumSpeed', 'Must be greater than zero');
      }
      if (typeof settings.maximumConsumption === 'number' && settings.maximumConsumption <= 0) {
        setInvalidField('maximumConsumption', 'Must be greater than zero');
      }
    },
    []
  );
  const modbusTcpValidator = useCallback(
    (
      settings: ModbusTcpSettings,
      setInvalidField: (field: NestedKeyOf<ModbusTcpSettings>, message: string) => void
    ) => {
      if (settings.port < 1 || settings.port > 65535) {
        setInvalidField('port', 'Port must be between 1 and 65535');
      }
    },
    []
  );

  return (
    <>
      <Editor<EcomateConfig>
        title="General"
        onEdited={onChanged}
        canDelete={false}
        validator={vesselValidator}
        item={config}
        editor={(item, setItem, validator) => <GeneralEdit config={item} onEdited={setItem} validator={validator} />}
        viewer={(item) => <GeneralView config={item} />}
      />
      <Editor<ModbusTcpSettings>
        title="Modbus TCP Interface"
        onEdited={changed}
        canDelete={false}
        validator={modbusTcpValidator}
        item={config?.modbusTcp}
        editor={(item, setItem, validator) => <Settings item={item} onEdited={setItem} validator={validator} />}
        viewer={(item) => <SettingsViewer item={item} />}
      />
    </>
  );
};

export default GeneralSettings;
